// ENTITY fr.hiji.metier.campaign.dto.front._360.InquiryTargetOOGDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { InquiryTargetType } from './InquiryTargetType';

export class InquiryTargetOOGDTO {

  idUser: number | null = 0;
  idObservableGesture: number | null = 0;
  observed: boolean | null = false;
  value: number | null = 0;
  text: string | null = '';
  targetType: InquiryTargetType | null = null;

  constructor(init?:Partial<InquiryTargetOOGDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front._360.InquiryTargetOOGDTO'] = InquiryTargetOOGDTO;

reverseMapping[InquiryTargetOOGDTO.name] = 'fr.hiji.metier.campaign.dto.front._360.InquiryTargetOOGDTO';

fields['fr.hiji.metier.campaign.dto.front._360.InquiryTargetOOGDTO']  = {
    idUser: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    observed: 'java.lang.Boolean',
    value: 'java.lang.Integer',
    text: 'java.lang.String',
    targetType: 'fr.hiji.metier.campaign.entities._360.InquiryTargetType'
};
