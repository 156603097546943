// ENTITY fr.hiji.metier.user.dto.back.UserEMALiteBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserEMALiteBODTO {

  idEMA: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  creationDate: Date | null = null;
  idUserManager: number | null = 0;
  idUserObserved: number | null = 0;

  constructor(init?:Partial<UserEMALiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.UserEMALiteBODTO'] = UserEMALiteBODTO;

reverseMapping[UserEMALiteBODTO.name] = 'fr.hiji.metier.user.dto.back.UserEMALiteBODTO';

fields['fr.hiji.metier.user.dto.back.UserEMALiteBODTO']  = {
    idEMA: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserManager: 'java.lang.Integer',
    idUserObserved: 'java.lang.Integer'
};
