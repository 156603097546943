// ENTITY fr.hiji.metier.mif.dto.back.formationsession.FormationSessionDiffusionListBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionDiffusionList } from './FormationSessionDiffusionList';

export class FormationSessionDiffusionListBODTO {

  formationSessionDiffusionList: FormationSessionDiffusionList | null = null;
  diffusionListName: string | null = '';

  constructor(init?:Partial<FormationSessionDiffusionListBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionDiffusionListBODTO'] = FormationSessionDiffusionListBODTO;

reverseMapping[FormationSessionDiffusionListBODTO.name] = 'fr.hiji.metier.mif.dto.back.formationsession.FormationSessionDiffusionListBODTO';

fields['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionDiffusionListBODTO']  = {
    formationSessionDiffusionList: 'fr.hiji.metier.mif.entities.session.FormationSessionDiffusionList',
    diffusionListName: 'java.lang.String'
};
