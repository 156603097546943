// ENTITY fr.hiji.metier.user.dto.back.InquiryLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class InquiryLiteBODTO {

  idInquiry: number | null = 0;
  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;

  constructor(init?:Partial<InquiryLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.InquiryLiteBODTO'] = InquiryLiteBODTO;

reverseMapping[InquiryLiteBODTO.name] = 'fr.hiji.metier.user.dto.back.InquiryLiteBODTO';

fields['fr.hiji.metier.user.dto.back.InquiryLiteBODTO']  = {
    idInquiry: 'java.lang.Integer',
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean'
};
