// ENTITY fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserResponsableBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CampaignPDIUserResponsable } from './CampaignPDIUserResponsable';

export class CampaignPDIUserResponsableBODTO {

  firstName: string | null = '';
  lastName: string | null = '';
  fonction: string | null = '';
  avatarExtension: string | null = '';
  userDel: boolean | null = false;
  user: CampaignPDIUserResponsable | null = null;

  constructor(init?:Partial<CampaignPDIUserResponsableBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserResponsableBODTO'] = CampaignPDIUserResponsableBODTO;

reverseMapping[CampaignPDIUserResponsableBODTO.name] = 'fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserResponsableBODTO';

fields['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserResponsableBODTO']  = {
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    fonction: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    userDel: 'java.lang.Boolean',
    user: 'fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserResponsable'
};
