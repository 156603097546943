// ENTITY fr.hiji.metier.mif.dto.back.formationQuiz.FormationQuizBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationQuiz } from './FormationQuiz';

export class FormationQuizBODTO {

  formationQuiz: FormationQuiz | null = null;
  quizName: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<FormationQuizBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formationQuiz.FormationQuizBODTO'] = FormationQuizBODTO;

reverseMapping[FormationQuizBODTO.name] = 'fr.hiji.metier.mif.dto.back.formationQuiz.FormationQuizBODTO';

fields['fr.hiji.metier.mif.dto.back.formationQuiz.FormationQuizBODTO']  = {
    formationQuiz: 'fr.hiji.metier.mif.entities.formation.FormationQuiz',
    quizName: 'com.ic2.sc.MultilingualString'
};
