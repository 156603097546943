// ENTITY fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportConfigBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { SurveyReportGenerationType } from './SurveyReportGenerationType';

export class SurveyReportConfigBODTO {

  reportGenerationType: SurveyReportGenerationType | null = null;
  withEngagement: boolean | null = false;
  withRESPECT: boolean | null = false;
  withNPS: boolean | null = false;
  withVerbatims: boolean | null = false;
  withHeatmaps: boolean | null = false;
  locale: string | null = '';

  constructor(init?:Partial<SurveyReportConfigBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportConfigBODTO'] = SurveyReportConfigBODTO;

reverseMapping[SurveyReportConfigBODTO.name] = 'fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportConfigBODTO';

fields['fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportConfigBODTO']  = {
    reportGenerationType: 'fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportGenerationType',
    withEngagement: 'java.lang.Boolean',
    withRESPECT: 'java.lang.Boolean',
    withNPS: 'java.lang.Boolean',
    withVerbatims: 'java.lang.Boolean',
    withHeatmaps: 'java.lang.Boolean',
    locale: 'java.lang.String'
};
