// fr.hiji.metier.campaign.services.back.survey.SurveyReportBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { SurveyReportConfigBODTO } from '../entities/SurveyReportConfigBODTO';
import { SurveyReportFilter } from '../entities/SurveyReportFilter';

@Injectable({
  providedIn: 'root',
})
export class SurveyReportBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  downloadModele(idCampaign: number, locale: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyReportBOService.downloadModele';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(locale, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  uploadModele(idCampaign: number, locale: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyReportBOService.uploadModele';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(locale, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteModele(idCampaign: number, locale: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyReportBOService.deleteModele';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(locale, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  generateVersusReport(filterA: SurveyReportFilter, filterB: SurveyReportFilter, config: SurveyReportConfigBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyReportBOService.generateVersusReport';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filterA, 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filterB, 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(config, 'fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportConfigBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.nio.file.Path');
  }

  generateVersusReports(idCampaign: number, config: SurveyReportConfigBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyReportBOService.generateVersusReports';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(config, 'fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportConfigBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.nio.file.Path');
  }

}
