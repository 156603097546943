// fr.hiji.metier.translate.services.TranslationBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount } from '@ic2/ic2-lib';
import { AttributBODTO } from '../entities/AttributBODTO';
import { DataTranslatorBODTO } from '../entities/DataTranslatorBODTO';

@Injectable({
  providedIn: 'root',
})
export class TranslationBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  translation(clazz: string, lang: string, langTranslate: string, onlyNoTranslate: boolean, nbPerPage: number, page: number): RpcRequestBuilder<ListWithCount<DataTranslatorBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TranslationBOService.translation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(clazz, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lang, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langTranslate, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(onlyNoTranslate, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getDataClass(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TranslationBOService.getDataClass';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  updateTranslateAll(clazz: string, dataTranslate: DataTranslatorBODTO[], langTranslate: string): RpcRequestBuilder<DataTranslatorBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TranslationBOService.updateTranslateAll';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(clazz, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(dataTranslate, 'java.util.List<fr.hiji.metier.translate.entities.DataTranslatorBODTO>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langTranslate, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  updateTranslate(clazz: string, ids: number[], attribut: AttributBODTO, langTranslate: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TranslationBOService.updateTranslate';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(clazz, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(ids, 'java.util.List<java.lang.Integer>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(attribut, 'fr.hiji.metier.translate.entities.AttributBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langTranslate, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  translateAPI(data: string, lang: string, langTarget: string): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TranslationBOService.translateAPI';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(data, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lang, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langTarget, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  translateALLApi(data: DataTranslatorBODTO[], lang: string, langTarget: string): RpcRequestBuilder<DataTranslatorBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TranslationBOService.translateALLApi';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(data, 'java.util.List<fr.hiji.metier.translate.entities.DataTranslatorBODTO>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lang, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langTarget, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
