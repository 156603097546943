// ENTITY fr.hiji.metier.reporting.dto.pdf180.ExchangeDetailDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ExchangeDetailDTO {

  name: string | null = '';
  nbExchange: number | null = 0;

  constructor(init?:Partial<ExchangeDetailDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.pdf180.ExchangeDetailDTO'] = ExchangeDetailDTO;

reverseMapping[ExchangeDetailDTO.name] = 'fr.hiji.metier.reporting.dto.pdf180.ExchangeDetailDTO';

fields['fr.hiji.metier.reporting.dto.pdf180.ExchangeDetailDTO']  = {
    name: 'java.lang.String',
    nbExchange: 'java.lang.Integer'
};
