// DB ENUM fr.hiji.metier.fbs.entities.FeedbackReaction generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FeedbackReaction {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly PARTY = new FeedbackReaction(1, "Fête", "PARTY");
  static readonly HEART = new FeedbackReaction(2, "Coeur", "HEART");
  static readonly LIKE = new FeedbackReaction(3, "J'aime", "LIKE");
  static readonly INTERROGATION = new FeedbackReaction(4, "Intérrogation", "INTERROGATION");

  static readonly values = [
    FeedbackReaction.PARTY,
 
    FeedbackReaction.HEART,
 
    FeedbackReaction.LIKE,
 
    FeedbackReaction.INTERROGATION
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FeedbackReaction.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FeedbackReaction.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.fbs.entities.FeedbackReaction'] = FeedbackReaction;
reverseMapping[FeedbackReaction.name] = 'fr.hiji.metier.fbs.entities.FeedbackReaction';
