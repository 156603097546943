// ENTITY fr.hiji.metier.fbs.entities.FeedbackCategory generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FeedbackCategory {

  idFeedbackCategory: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  icon: string | null = '';
  order: number | null = 0;
  del: boolean | null = false;

  constructor(init?:Partial<FeedbackCategory>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.fbs.entities.FeedbackCategory'] = FeedbackCategory;

reverseMapping[FeedbackCategory.name] = 'fr.hiji.metier.fbs.entities.FeedbackCategory';

fields['fr.hiji.metier.fbs.entities.FeedbackCategory']  = {
    idFeedbackCategory: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    icon: 'java.lang.String',
    order: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
