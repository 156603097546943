// ENTITY fr.hiji.metier.mif.dto.front.FormationSessionParticipantExpectationsDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionParticipantExpectation } from './FormationSessionParticipantExpectation';
import { Criteria } from './Criteria';

export class FormationSessionParticipantExpectationsDTO {

  expectations: FormationSessionParticipantExpectation[] | null = [];
  idFormationSession: number | null = 0;
  idUser: number | null = 0;
  criteriaExpectations: Criteria | null = null;
  criteriaBusinessExpectations: Criteria | null = null;
  expectationsParticipantEnabled: boolean | null = false;
  expectationsManagerEnabled: boolean | null = false;

  constructor(init?:Partial<FormationSessionParticipantExpectationsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.FormationSessionParticipantExpectationsDTO'] = FormationSessionParticipantExpectationsDTO;

reverseMapping[FormationSessionParticipantExpectationsDTO.name] = 'fr.hiji.metier.mif.dto.front.FormationSessionParticipantExpectationsDTO';

fields['fr.hiji.metier.mif.dto.front.FormationSessionParticipantExpectationsDTO']  = {
    expectations: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionParticipantExpectation>',
    idFormationSession: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    criteriaExpectations: 'fr.hiji.metier.referential.entities.Criteria',
    criteriaBusinessExpectations: 'fr.hiji.metier.referential.entities.Criteria',
    expectationsParticipantEnabled: 'java.lang.Boolean',
    expectationsManagerEnabled: 'java.lang.Boolean'
};
