// ENTITY fr.hiji.metier.home.dto.front.InquiryCreateDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class InquiryCreateDTO {

  idInquiry: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  endDate: Date | null = null;
  deployed: boolean | null = false;
  updateDate: Date | null = null;
  autoObsDraft: boolean | null = false;
  autoObsDone: boolean | null = false;

  constructor(init?:Partial<InquiryCreateDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.InquiryCreateDTO'] = InquiryCreateDTO;

reverseMapping[InquiryCreateDTO.name] = 'fr.hiji.metier.home.dto.front.InquiryCreateDTO';

fields['fr.hiji.metier.home.dto.front.InquiryCreateDTO']  = {
    idInquiry: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    endDate: 'java.time.LocalDate',
    deployed: 'java.lang.Boolean',
    updateDate: 'java.time.Instant',
    autoObsDraft: 'java.lang.Boolean',
    autoObsDone: 'java.lang.Boolean'
};
