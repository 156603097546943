// ENTITY fr.hiji.metier.mif.entities.observation.FormationObservation generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationObservationType } from './FormationObservationType';

export class FormationObservation {

  idFormationObservation: number | null = 0;
  idFormationSession: number | null = 0;
  idUserObserved: number | null = null;
  type: FormationObservationType | null = null;
  creationDate: Date | null = null;

  constructor(init?:Partial<FormationObservation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.observation.FormationObservation'] = FormationObservation;

reverseMapping[FormationObservation.name] = 'fr.hiji.metier.mif.entities.observation.FormationObservation';

fields['fr.hiji.metier.mif.entities.observation.FormationObservation']  = {
    idFormationObservation: 'java.lang.Integer',
    idFormationSession: 'java.lang.Integer',
    idUserObserved: 'java.lang.Integer',
    type: 'fr.hiji.metier.mif.entities.observation.FormationObservationType',
    creationDate: 'java.time.Instant'
};
