// ENTITY fr.hiji.metier.reporting.dto.back.Report180ConfigDepartmentBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { Report180ConfigDepartment } from './Report180ConfigDepartment';

export class Report180ConfigDepartmentBODTO {

  service: Report180ConfigDepartment | null = null;
  populationName: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;

  constructor(init?:Partial<Report180ConfigDepartmentBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.Report180ConfigDepartmentBODTO'] = Report180ConfigDepartmentBODTO;

reverseMapping[Report180ConfigDepartmentBODTO.name] = 'fr.hiji.metier.reporting.dto.back.Report180ConfigDepartmentBODTO';

fields['fr.hiji.metier.reporting.dto.back.Report180ConfigDepartmentBODTO']  = {
    service: 'fr.hiji.metier.reporting.entities._180.Report180ConfigDepartment',
    populationName: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean'
};
