// DB ENUM fr.hiji.metier.reporting.dto.pdf180.PodiumType generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class PodiumType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly PER_CAMPAIGN = new PodiumType(1, "Par campagne", "PER_CAMPAIGN");
  static readonly PER_REPOSITORY = new PodiumType(2, "Par référentiel", "PER_REPOSITORY");

  static readonly values = [
    PodiumType.PER_CAMPAIGN,
 
    PodiumType.PER_REPOSITORY
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of PodiumType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of PodiumType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.reporting.dto.pdf180.PodiumType'] = PodiumType;
reverseMapping[PodiumType.name] = 'fr.hiji.metier.reporting.dto.pdf180.PodiumType';
