// ENTITY fr.hiji.metier.reseau.dto.back.OrganisationBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Organisation } from './Organisation';

export class OrganisationBODTO {

  organisation: Organisation | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  nbUsers: number | null = 0;

  constructor(init?:Partial<OrganisationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.dto.back.OrganisationBODTO'] = OrganisationBODTO;

reverseMapping[OrganisationBODTO.name] = 'fr.hiji.metier.reseau.dto.back.OrganisationBODTO';

fields['fr.hiji.metier.reseau.dto.back.OrganisationBODTO']  = {
    organisation: 'fr.hiji.metier.reseau.entities.Organisation',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    nbUsers: 'java.lang.Integer'
};
