// ENTITY fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizQuestionDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { QuestionQCMAnswerDTO } from './QuestionQCMAnswerDTO';
import { QuestionType } from './QuestionType';
import { QuestionFlatTag } from './QuestionFlatTag';

export class ModalityQuizQuestionDTO {

  idQuestion: number | null = 0;
  order: number | null = 0;
  question: MultilingualString | null = new MultilingualString();
  image: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  type: QuestionType | null = null;
  showNumberOfAnswersHint: boolean | null = false;
  nbOfAnswers: number | null = 0;
  tags: QuestionFlatTag[] | null = [];
  answers: QuestionQCMAnswerDTO[] | null = [];

  constructor(init?:Partial<ModalityQuizQuestionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizQuestionDTO'] = ModalityQuizQuestionDTO;

reverseMapping[ModalityQuizQuestionDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizQuestionDTO';

fields['fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizQuestionDTO']  = {
    idQuestion: 'java.lang.Integer',
    order: 'java.lang.Integer',
    question: 'com.ic2.sc.MultilingualString',
    image: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    type: 'fr.hiji.metier.referential.entities.quiz.QuestionType',
    showNumberOfAnswersHint: 'java.lang.Boolean',
    nbOfAnswers: 'java.lang.Integer',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.quiz.QuestionFlatTag>',
    answers: 'java.util.List<fr.hiji.metier.certif.dto.front.modality.quiz.QuestionQCMAnswerDTO>'
};
