// ENTITY fr.hiji.metier.reseau.entities.Organisation generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OrganisationType } from './OrganisationType';

export class Organisation {

  idOrganisation: number | null = 0;
  idParent: number | null = 0;
  idOld: number | null = 0;
  name: string | null = '';
  ref: string | null = '';
  type: OrganisationType | null = null;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = 0;
  del: boolean | null = false;

  constructor(init?:Partial<Organisation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.entities.Organisation'] = Organisation;

reverseMapping[Organisation.name] = 'fr.hiji.metier.reseau.entities.Organisation';

fields['fr.hiji.metier.reseau.entities.Organisation']  = {
    idOrganisation: 'java.lang.Integer',
    idParent: 'java.lang.Integer',
    idOld: 'java.lang.Integer',
    name: 'java.lang.String',
    ref: 'java.lang.String',
    type: 'fr.hiji.metier.reseau.entities.OrganisationType',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
