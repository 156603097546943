// ENTITY fr.hiji.metier.mif.dto.back.formation.FormationBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationFileBODTO } from './FormationFileBODTO';
import { FormationTrainerBODTO } from './FormationTrainerBODTO';
import { FormationTag } from './FormationTag';
import { Formation } from './Formation';

export class FormationBODTO {

  formation: Formation | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  deleteImage: Boolean | null = null;
  trainers: FormationTrainerBODTO[] | null = [];
  files: FormationFileBODTO[] | null = [];
  tags: FormationTag[] | null = [];
  searchLanguagesMatch: string[] | null = [];

  constructor(init?:Partial<FormationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formation.FormationBODTO'] = FormationBODTO;

reverseMapping[FormationBODTO.name] = 'fr.hiji.metier.mif.dto.back.formation.FormationBODTO';

fields['fr.hiji.metier.mif.dto.back.formation.FormationBODTO']  = {
    formation: 'fr.hiji.metier.mif.entities.formation.Formation',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    deleteImage: 'java.lang.Boolean',
    trainers: 'java.util.List<fr.hiji.metier.mif.dto.back.formation.FormationTrainerBODTO>',
    files: 'java.util.List<fr.hiji.metier.mif.dto.back.formation.FormationFileBODTO>',
    tags: 'java.util.List<fr.hiji.metier.mif.entities.formation.FormationTag>',
    searchLanguagesMatch: 'java.util.List<java.lang.String>'
};
