// ENTITY fr.hiji.metier.certif.dto.front.CertificationLiteDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class CertificationLiteDTO {

  idCertification: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  certified: boolean | null = false;
  assessor: boolean | null = false;
  imageExtension: string | null = '';

  constructor(init?:Partial<CertificationLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.CertificationLiteDTO'] = CertificationLiteDTO;

reverseMapping[CertificationLiteDTO.name] = 'fr.hiji.metier.certif.dto.front.CertificationLiteDTO';

fields['fr.hiji.metier.certif.dto.front.CertificationLiteDTO']  = {
    idCertification: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    certified: 'java.lang.Boolean',
    assessor: 'java.lang.Boolean',
    imageExtension: 'java.lang.String'
};
