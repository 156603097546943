// ENTITY fr.hiji.metier.campaign.dto.front.SurveyDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ObservationObservableGesturesDTO } from './ObservationObservableGesturesDTO';
import { Criteria } from './Criteria';

export class SurveyDTO {

  idCampaign: number | null = 0;
  campaignName: MultilingualString | null = new MultilingualString();
  campaignDescription: MultilingualString | null = new MultilingualString();
  idRepository: number | null = 0;
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDescription: MultilingualString | null = new MultilingualString();
  observableGestures: ObservationObservableGesturesDTO[] | null = [];
  criterias: Criteria[] | null = [];
  startAnsweringDate: Date | null = null;
  draft: boolean | null = false;
  idObservation: number | null = 0;
  hideSkill: boolean | null = false;
  randomizeOrder: boolean | null = false;
  forceAnswerAllQuestions: boolean | null = false;
  forceShowDescription: boolean | null = false;

  constructor(init?:Partial<SurveyDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.SurveyDTO'] = SurveyDTO;

reverseMapping[SurveyDTO.name] = 'fr.hiji.metier.campaign.dto.front.SurveyDTO';

fields['fr.hiji.metier.campaign.dto.front.SurveyDTO']  = {
    idCampaign: 'java.lang.Integer',
    campaignName: 'com.ic2.sc.MultilingualString',
    campaignDescription: 'com.ic2.sc.MultilingualString',
    idRepository: 'java.lang.Integer',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDescription: 'com.ic2.sc.MultilingualString',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservationObservableGesturesDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    startAnsweringDate: 'java.time.Instant',
    draft: 'java.lang.Boolean',
    idObservation: 'java.lang.Integer',
    hideSkill: 'java.lang.Boolean',
    randomizeOrder: 'java.lang.Boolean',
    forceAnswerAllQuestions: 'java.lang.Boolean',
    forceShowDescription: 'java.lang.Boolean'
};
