// ENTITY fr.hiji.metier.campaign.dto.back.CampaignBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CampaignRepositoryBODTO } from './CampaignRepositoryBODTO';
import { CampaignPDIInfosBODTO } from './CampaignPDIInfosBODTO';
import { Campaign } from './Campaign';
import { Campaign180Infos } from './Campaign180Infos';
import { CampaignEmail } from './CampaignEmail';
import { SurveyBODTO } from './SurveyBODTO';
import { Campaign360BODTO } from './Campaign360BODTO';
import { CampaignUserAccessBODTO } from './CampaignUserAccessBODTO';

export class CampaignBODTO {

  campaign: Campaign | null = null;
  repositories: CampaignRepositoryBODTO[] | null = [];
  userCreator: string | null = '';
  userUpdater: string | null = '';
  campaign180: Campaign180Infos | null = null;
  campaign360: Campaign360BODTO | null = null;
  survey: SurveyBODTO | null = null;
  pdi: CampaignPDIInfosBODTO | null = null;
  access: CampaignUserAccessBODTO[] | null = [];
  customizedEmails: CampaignEmail[] | null = [];

  constructor(init?:Partial<CampaignBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.CampaignBODTO'] = CampaignBODTO;

reverseMapping[CampaignBODTO.name] = 'fr.hiji.metier.campaign.dto.back.CampaignBODTO';

fields['fr.hiji.metier.campaign.dto.back.CampaignBODTO']  = {
    campaign: 'fr.hiji.metier.campaign.entities.Campaign',
    repositories: 'java.util.List<fr.hiji.metier.campaign.dto.back.CampaignRepositoryBODTO>',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    campaign180: 'fr.hiji.metier.campaign.entities._180.Campaign180Infos',
    campaign360: 'fr.hiji.metier.campaign.dto.back._360.Campaign360BODTO',
    survey: 'fr.hiji.metier.campaign.dto.back.survey.SurveyBODTO',
    pdi: 'fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIInfosBODTO',
    access: 'java.util.List<fr.hiji.metier.campaign.dto.back.CampaignUserAccessBODTO>',
    customizedEmails: 'java.util.List<fr.hiji.metier.campaign.entities.CampaignEmail>'
};
