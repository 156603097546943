// ENTITY fr.hiji.metier.mif.dto.back.formationsession.FormationSessionTrainerBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionTrainer } from './FormationSessionTrainer';

export class FormationSessionTrainerBODTO {

  formationSessionTrainer: FormationSessionTrainer | null = null;
  firstName: string | null = '';
  lastName: string | null = '';
  del: boolean | null = false;

  constructor(init?:Partial<FormationSessionTrainerBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionTrainerBODTO'] = FormationSessionTrainerBODTO;

reverseMapping[FormationSessionTrainerBODTO.name] = 'fr.hiji.metier.mif.dto.back.formationsession.FormationSessionTrainerBODTO';

fields['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionTrainerBODTO']  = {
    formationSessionTrainer: 'fr.hiji.metier.mif.entities.session.FormationSessionTrainer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    del: 'java.lang.Boolean'
};
