// ENTITY fr.hiji.metier.ema.dto.front.EMAObservationOGCDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAUserOGC } from './EMAUserOGC';

export class EMAObservationOGCDTO {

  emaUserOGC: EMAUserOGC | null = null;
  value: number | null = 0;
  text: string | null = '';
  observed: boolean | null = false;
  answerDate: Date | null = null;

  constructor(init?:Partial<EMAObservationOGCDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMAObservationOGCDTO'] = EMAObservationOGCDTO;

reverseMapping[EMAObservationOGCDTO.name] = 'fr.hiji.metier.ema.dto.front.EMAObservationOGCDTO';

fields['fr.hiji.metier.ema.dto.front.EMAObservationOGCDTO']  = {
    emaUserOGC: 'fr.hiji.metier.ema.entities.EMAUserOGC',
    value: 'java.lang.Integer',
    text: 'java.lang.String',
    observed: 'java.lang.Boolean',
    answerDate: 'java.time.Instant'
};
