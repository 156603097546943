// ENTITY fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Genre } from './Genre';

export class CampaignStatsFilterBODTO {

  idCampaign: number | null = 0;
  start: Date | null = null;
  end: Date | null = null;
  startAge: number | null = 0;
  endAge: number | null = 0;
  startSeniority: number | null = 0;
  endSeniority: number | null = 0;
  gender: Genre | null = null;
  langue: string | null = '';
  idUserEquipe: number | null = 0;
  equipeDirecte: boolean | null = false;
  equipeGlobale: boolean | null = false;
  idsRepositories: number[] | null = [];
  idsOrganisation: number[] | null = [];
  idsFonction: number[] | null = [];
  idsPopulation: number[] | null = [];

  constructor(init?:Partial<CampaignStatsFilterBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO'] = CampaignStatsFilterBODTO;

reverseMapping[CampaignStatsFilterBODTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO';

fields['fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO']  = {
    idCampaign: 'java.lang.Integer',
    start: 'java.time.Instant',
    end: 'java.time.Instant',
    startAge: 'java.lang.Integer',
    endAge: 'java.lang.Integer',
    startSeniority: 'java.lang.Integer',
    endSeniority: 'java.lang.Integer',
    gender: 'fr.hiji.metier.user.entities.Genre',
    langue: 'java.lang.String',
    idUserEquipe: 'java.lang.Integer',
    equipeDirecte: 'java.lang.Boolean',
    equipeGlobale: 'java.lang.Boolean',
    idsRepositories: 'java.util.List<java.lang.Integer>',
    idsOrganisation: 'java.util.List<java.lang.Integer>',
    idsFonction: 'java.util.List<java.lang.Integer>',
    idsPopulation: 'java.util.List<java.lang.Integer>'
};
