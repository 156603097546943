// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleGlobalDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleGlobalDTO {

  manualHierarchicalManagementEnabled: boolean | null = false;
  hideHomeTeamButtonsEnabled: boolean | null = false;
  signupEnabled: boolean | null = false;
  localAccountLoginEnabled: boolean | null = false;
  opinionEnabled: boolean | null = false;
  nbMiniUsersStats: number | null = 0;
  emailAssistanceClient: string | null = '';
  modeMaintenance: boolean | null = false;
  displayFunctionsFront: boolean | null = false;
  useCampaignAccessControl: boolean | null = false;
  opinionDataCollectionEnabled: boolean | null = false;
  hideConnexionDateToGestionnaires: boolean | null = false;
  userImportEnabled: boolean | null = false;
  emailUserImportEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleGlobalDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleGlobalDTO'] = ModuleGlobalDTO;

reverseMapping[ModuleGlobalDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleGlobalDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleGlobalDTO']  = {
    manualHierarchicalManagementEnabled: 'java.lang.Boolean',
    hideHomeTeamButtonsEnabled: 'java.lang.Boolean',
    signupEnabled: 'java.lang.Boolean',
    localAccountLoginEnabled: 'java.lang.Boolean',
    opinionEnabled: 'java.lang.Boolean',
    nbMiniUsersStats: 'java.lang.Integer',
    emailAssistanceClient: 'java.lang.String',
    modeMaintenance: 'java.lang.Boolean',
    displayFunctionsFront: 'java.lang.Boolean',
    useCampaignAccessControl: 'java.lang.Boolean',
    opinionDataCollectionEnabled: 'java.lang.Boolean',
    hideConnexionDateToGestionnaires: 'java.lang.Boolean',
    userImportEnabled: 'java.lang.Boolean',
    emailUserImportEnabled: 'java.lang.Boolean'
};
