// DB ENUM fr.hiji.metier.campaign.entities.AgeRange generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class AgeRange {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly MOINS_20 = new AgeRange(1, "-20", "MOINS_20");
  static readonly _20_29 = new AgeRange(2, "20-29", "_20_29");
  static readonly _30_39 = new AgeRange(3, "30-39", "_30_39");
  static readonly _40_49 = new AgeRange(4, "40-49", "_40_49");
  static readonly _50_59 = new AgeRange(5, "50-59", "_50_59");
  static readonly _60_ET_PLUS = new AgeRange(6, "60+", "_60_ET_PLUS");

  static readonly values = [
    AgeRange.MOINS_20,
 
    AgeRange._20_29,
 
    AgeRange._30_39,
 
    AgeRange._40_49,
 
    AgeRange._50_59,
 
    AgeRange._60_ET_PLUS
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of AgeRange.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of AgeRange.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.campaign.entities.AgeRange'] = AgeRange;
reverseMapping[AgeRange.name] = 'fr.hiji.metier.campaign.entities.AgeRange';
