// ENTITY fr.hiji.metier.ema.dto.front.EMAObservationDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAObservationObservableGesturesDTO } from './EMAObservationObservableGesturesDTO';
import { EMASkillMessageWithUserDTO } from './EMASkillMessageWithUserDTO';
import { EMAObservationOGCDTO } from './EMAObservationOGCDTO';
import { Criteria } from './Criteria';
import { EMAStep } from './EMAStep';
import { EMAObservation } from './EMAObservation';

export class EMAObservationDTO {

  emaObservation: EMAObservation | null = null;
  observableGestures: EMAObservationObservableGesturesDTO[] | null = [];
  observableGesturesCustom: EMAObservationOGCDTO[] | null = [];
  criterias: Criteria[] | null = [];
  enabledSteps: EMAStep[] | null = [];
  skillMessages: EMASkillMessageWithUserDTO[] | null = [];
  repositorySelectedExists: boolean | null = false;
  allowObsComment: boolean | null = false;
  allowExchangeSkillComment: boolean | null = false;
  hasPreviousEMA: boolean | null = false;
  message: string | null = '';
  supportPlan: string | null = '';
  coach: boolean | null = false;

  constructor(init?:Partial<EMAObservationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMAObservationDTO'] = EMAObservationDTO;

reverseMapping[EMAObservationDTO.name] = 'fr.hiji.metier.ema.dto.front.EMAObservationDTO';

fields['fr.hiji.metier.ema.dto.front.EMAObservationDTO']  = {
    emaObservation: 'fr.hiji.metier.ema.entities.observation.EMAObservation',
    observableGestures: 'java.util.List<fr.hiji.metier.ema.dto.front.EMAObservationObservableGesturesDTO>',
    observableGesturesCustom: 'java.util.List<fr.hiji.metier.ema.dto.front.EMAObservationOGCDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    enabledSteps: 'java.util.List<fr.hiji.metier.ema.entities.EMAStep>',
    skillMessages: 'java.util.List<fr.hiji.metier.ema.dto.front.EMASkillMessageWithUserDTO>',
    repositorySelectedExists: 'java.lang.Boolean',
    allowObsComment: 'java.lang.Boolean',
    allowExchangeSkillComment: 'java.lang.Boolean',
    hasPreviousEMA: 'java.lang.Boolean',
    message: 'java.lang.String',
    supportPlan: 'java.lang.String',
    coach: 'java.lang.Boolean'
};
