// fr.hiji.metier.mif.services.front.EVAQuizServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { QuizEVADTO } from '../entities/QuizEVADTO';

@Injectable({
  providedIn: 'root',
})
export class EVAQuizService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  answerQuiz(dto: QuizEVADTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EVAQuizService.answerQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.front.manager.QuizEVADTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getQuiz(idFormationSession: number, idUserParticipant: number): RpcRequestBuilder<QuizEVADTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EVAQuizService.getQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUserParticipant, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.manager.QuizEVADTO');
  }

}
