// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleEventDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleEventDTO {

  moduleEventEnabled: boolean | null = false;
  anonymousEventEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleEventDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleEventDTO'] = ModuleEventDTO;

reverseMapping[ModuleEventDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleEventDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleEventDTO']  = {
    moduleEventEnabled: 'java.lang.Boolean',
    anonymousEventEnabled: 'java.lang.Boolean'
};
