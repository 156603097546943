// ENTITY fr.hiji.metier.ema.dto.front.EMAObservationObservableGesturesDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMAUserOG } from './EMAUserOG';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class EMAObservationObservableGesturesDTO {

  emaUserOG: EMAUserOG | null = null;
  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  observableGestureDescription: MultilingualString | null = new MultilingualString();
  idCriteria: number | null = 0;
  tags: ObservableGestureFlatTag[] | null = [];
  idRepository: number | null = 0;
  order: number | null = 0;
  value: number | null = 0;
  text: string | null = '';
  observed: boolean | null = false;
  answerDate: Date | null = null;
  isComplementary: boolean | null = false;

  constructor(init?:Partial<EMAObservationObservableGesturesDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMAObservationObservableGesturesDTO'] = EMAObservationObservableGesturesDTO;

reverseMapping[EMAObservationObservableGesturesDTO.name] = 'fr.hiji.metier.ema.dto.front.EMAObservationObservableGesturesDTO';

fields['fr.hiji.metier.ema.dto.front.EMAObservationObservableGesturesDTO']  = {
    emaUserOG: 'fr.hiji.metier.ema.entities.EMAUserOG',
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    observableGestureDescription: 'com.ic2.sc.MultilingualString',
    idCriteria: 'java.lang.Integer',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>',
    idRepository: 'java.lang.Integer',
    order: 'java.lang.Integer',
    value: 'java.lang.Integer',
    text: 'java.lang.String',
    observed: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    isComplementary: 'java.lang.Boolean'
};
