// ENTITY fr.hiji.metier.conf.dto.ModulesBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModuleMesureImpactFormationDTO } from './ModuleMesureImpactFormationDTO';
import { Module360DTO } from './Module360DTO';
import { ModuleBilanDTO } from './ModuleBilanDTO';
import { ModuleTeamBoardDTO } from './ModuleTeamBoardDTO';
import { ModuleEventDTO } from './ModuleEventDTO';
import { ModuleFBSDTO } from './ModuleFBSDTO';
import { Module180DTO } from './Module180DTO';
import { ModuleEMADTO } from './ModuleEMADTO';
import { ModuleTMSDTO } from './ModuleTMSDTO';
import { ModulePDIDTO } from './ModulePDIDTO';
import { ModuleCertificationDTO } from './ModuleCertificationDTO';
import { ModuleSurveyDTO } from './ModuleSurveyDTO';
import { ModuleGlobalDTO } from './ModuleGlobalDTO';

export class ModulesBODTO {

  moduleGlobalDTO: ModuleGlobalDTO | null = null;
  module180DTO: Module180DTO | null = null;
  module360DTO: Module360DTO | null = null;
  moduleFBSDTO: ModuleFBSDTO | null = null;
  moduleSurveyDTO: ModuleSurveyDTO | null = null;
  moduleBilanDTO: ModuleBilanDTO | null = null;
  modulePDIDTO: ModulePDIDTO | null = null;
  moduleCertificationDTO: ModuleCertificationDTO | null = null;
  moduleEventDTO: ModuleEventDTO | null = null;
  moduleMesureImpactFormation: ModuleMesureImpactFormationDTO | null = null;
  moduleEMA: ModuleEMADTO | null = null;
  moduleTeamBoard: ModuleTeamBoardDTO | null = null;
  moduleTMSDTO: ModuleTMSDTO | null = null;

  constructor(init?:Partial<ModulesBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.ModulesBODTO'] = ModulesBODTO;

reverseMapping[ModulesBODTO.name] = 'fr.hiji.metier.conf.dto.ModulesBODTO';

fields['fr.hiji.metier.conf.dto.ModulesBODTO']  = {
    moduleGlobalDTO: 'fr.hiji.metier.conf.dto.submodules.ModuleGlobalDTO',
    module180DTO: 'fr.hiji.metier.conf.dto.submodules.Module180DTO',
    module360DTO: 'fr.hiji.metier.conf.dto.submodules.Module360DTO',
    moduleFBSDTO: 'fr.hiji.metier.conf.dto.submodules.ModuleFBSDTO',
    moduleSurveyDTO: 'fr.hiji.metier.conf.dto.submodules.ModuleSurveyDTO',
    moduleBilanDTO: 'fr.hiji.metier.conf.dto.submodules.ModuleBilanDTO',
    modulePDIDTO: 'fr.hiji.metier.conf.dto.submodules.ModulePDIDTO',
    moduleCertificationDTO: 'fr.hiji.metier.conf.dto.submodules.ModuleCertificationDTO',
    moduleEventDTO: 'fr.hiji.metier.conf.dto.submodules.ModuleEventDTO',
    moduleMesureImpactFormation: 'fr.hiji.metier.conf.dto.submodules.ModuleMesureImpactFormationDTO',
    moduleEMA: 'fr.hiji.metier.conf.dto.submodules.ModuleEMADTO',
    moduleTeamBoard: 'fr.hiji.metier.conf.dto.submodules.ModuleTeamBoardDTO',
    moduleTMSDTO: 'fr.hiji.metier.conf.dto.submodules.ModuleTMSDTO'
};
