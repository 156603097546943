// ENTITY com.ic2.entity.UserRight generated by ic2 3.0.0-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserRight {

  idUserRight: number | null = 0;
  idUser: number | null = 0;
  idRight: number | null = 0;

  constructor(init?:Partial<UserRight>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.entity.UserRight'] = UserRight;

reverseMapping[UserRight.name] = 'com.ic2.entity.UserRight';

fields['com.ic2.entity.UserRight']  = {
    idUserRight: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    idRight: 'java.lang.Integer'
};
