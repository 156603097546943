// ENTITY fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerOG generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityRoleplayAnswerOG {

  idModalityRoleplayAnswer: number | null = 0;
  idObservableGesture: number | null = 0;
  value: number | null = 0;
  observed: boolean | null = false;
  answerDate: Date | null = null;
  text: string | null = '';

  constructor(init?:Partial<ModalityRoleplayAnswerOG>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerOG'] = ModalityRoleplayAnswerOG;

reverseMapping[ModalityRoleplayAnswerOG.name] = 'fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerOG';

fields['fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerOG']  = {
    idModalityRoleplayAnswer: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    value: 'java.lang.Integer',
    observed: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    text: 'java.lang.String'
};
