// ENTITY fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationSessionParticipantDTO {

  idFormation: number | null = 0;
  formationName: MultilingualString | null = new MultilingualString();
  formationColorCode: string | null = '';
  idFormationSession: number | null = 0;
  formationSessionName: string | null = '';
  formationImageExtension: string | null = '';
  formationSessionColorCode: string | null = '';
  minDate: Date | null = null;
  maxDate: Date | null = null;
  acquisitionBeforeAvailable: boolean | null = false;
  satisfactionAvailable: boolean | null = false;
  acquisitionAfterAvailable: boolean | null = false;
  actionAvailable: boolean | null = false;
  acquisitionEnabled: boolean | null = false;
  actionEnabled: boolean | null = false;
  actionEvaluationJ10Enabled: boolean | null = false;
  activationEnabled: boolean | null = false;
  expectationsParticipantEnabled: boolean | null = false;
  expectationsParticipantEvaluationEnabled: boolean | null = false;
  expectationsManagerEnabled: boolean | null = false;
  attendanceEnabled: boolean | null = false;
  acquisitionBeforeDone: boolean | null = false;
  acquisitionAfterDone: boolean | null = false;
  satisfactionDone: boolean | null = false;
  actionDone: boolean | null = false;
  evaDone: boolean | null = false;
  dateEVA: Date | null = null;

  constructor(init?:Partial<FormationSessionParticipantDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantDTO'] = FormationSessionParticipantDTO;

reverseMapping[FormationSessionParticipantDTO.name] = 'fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantDTO';

fields['fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantDTO']  = {
    idFormation: 'java.lang.Integer',
    formationName: 'com.ic2.sc.MultilingualString',
    formationColorCode: 'java.lang.String',
    idFormationSession: 'java.lang.Integer',
    formationSessionName: 'java.lang.String',
    formationImageExtension: 'java.lang.String',
    formationSessionColorCode: 'java.lang.String',
    minDate: 'java.time.LocalDate',
    maxDate: 'java.time.LocalDate',
    acquisitionBeforeAvailable: 'java.lang.Boolean',
    satisfactionAvailable: 'java.lang.Boolean',
    acquisitionAfterAvailable: 'java.lang.Boolean',
    actionAvailable: 'java.lang.Boolean',
    acquisitionEnabled: 'java.lang.Boolean',
    actionEnabled: 'java.lang.Boolean',
    actionEvaluationJ10Enabled: 'java.lang.Boolean',
    activationEnabled: 'java.lang.Boolean',
    expectationsParticipantEnabled: 'java.lang.Boolean',
    expectationsParticipantEvaluationEnabled: 'java.lang.Boolean',
    expectationsManagerEnabled: 'java.lang.Boolean',
    attendanceEnabled: 'java.lang.Boolean',
    acquisitionBeforeDone: 'java.lang.Boolean',
    acquisitionAfterDone: 'java.lang.Boolean',
    satisfactionDone: 'java.lang.Boolean',
    actionDone: 'java.lang.Boolean',
    evaDone: 'java.lang.Boolean',
    dateEVA: 'java.time.Instant'
};
