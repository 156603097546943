// ENTITY fr.hiji.metier.user.entities.HijiUser generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Genre } from './Genre';

export class HijiUser {

  idUser: number | null = 0;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = 0;
  lastFrontConnectionDate: Date | null = null;
  lastBackConnectionDate: Date | null = null;
  avatarExtension: string | null = '';
  RGPDValidated: boolean | null = false;
  RGPDValidationdate: Date | null = null;
  matricule: string | null = '';
  service: string | null = '';
  idFonction: number | null = 0;
  resetPwdToken: string | null = '';
  autoUpdateDisabled: boolean | null = false;
  updateMessageSeen: boolean | null = false;
  birthDate: Date | null = null;
  hiringDate: Date | null = null;
  gender: Genre | null = null;
  locale: string | null = '';
  filter1: string | null = '';
  filter2: string | null = '';
  filter3: string | null = '';
  debugImport: string | null = '';
  deletedForRGPDReasons: boolean | null = false;

  constructor(init?:Partial<HijiUser>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.entities.HijiUser'] = HijiUser;

reverseMapping[HijiUser.name] = 'fr.hiji.metier.user.entities.HijiUser';

fields['fr.hiji.metier.user.entities.HijiUser']  = {
    idUser: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    lastFrontConnectionDate: 'java.time.Instant',
    lastBackConnectionDate: 'java.time.Instant',
    avatarExtension: 'java.lang.String',
    RGPDValidated: 'java.lang.Boolean',
    RGPDValidationdate: 'java.time.Instant',
    matricule: 'java.lang.String',
    service: 'java.lang.String',
    idFonction: 'java.lang.Integer',
    resetPwdToken: 'java.lang.String',
    autoUpdateDisabled: 'java.lang.Boolean',
    updateMessageSeen: 'java.lang.Boolean',
    birthDate: 'java.time.LocalDate',
    hiringDate: 'java.time.LocalDate',
    gender: 'fr.hiji.metier.user.entities.Genre',
    locale: 'java.lang.String',
    filter1: 'java.lang.String',
    filter2: 'java.lang.String',
    filter3: 'java.lang.String',
    debugImport: 'java.lang.String',
    deletedForRGPDReasons: 'java.lang.Boolean'
};
