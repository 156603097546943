// fr.hiji.metier.mif.services.back.formation.FormationBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FormationBODTO } from '../entities/FormationBODTO';
import { FormationTrainerBODTO } from '../entities/FormationTrainerBODTO';

@Injectable({
  providedIn: 'root',
})
export class FormationBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFormations(filter: GenericFilter): RpcRequestBuilder<ListWithCount<FormationBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationBOService.getFormations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getFormation(id: number): RpcRequestBuilder<FormationBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationBOService.getFormation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.formation.FormationBODTO');
  }

  saveFormation(dto: FormationBODTO): RpcRequestBuilder<FormationBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationBOService.saveFormation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.back.formation.FormationBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.formation.FormationBODTO');
  }

  getFormationTrainers(idFormation: number): RpcRequestBuilder<FormationTrainerBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationBOService.getFormationTrainers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getDistinctFormationTags(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationBOService.getDistinctFormationTags';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
