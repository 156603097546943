// fr.hiji.metier.teamboard.services.TeamBoardServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EMATeamObjectiveDTO } from '../entities/EMATeamObjectiveDTO';
import { TeamBoardLiteDTO } from '../entities/TeamBoardLiteDTO';
import { TeamBoardDTO } from '../entities/TeamBoardDTO';

@Injectable({
  providedIn: 'root',
})
export class TeamBoardService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTeamBoard(idTeamBoard: number): RpcRequestBuilder<TeamBoardDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TeamBoardService.getTeamBoard';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idTeamBoard, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.teamboard.dto.TeamBoardDTO');
  }

  saveTeamBoard(teamBoardDTO: TeamBoardDTO): RpcRequestBuilder<TeamBoardDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TeamBoardService.saveTeamBoard';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(teamBoardDTO, 'fr.hiji.metier.teamboard.dto.TeamBoardDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.teamboard.dto.TeamBoardDTO');
  }

  getTeamResults(idUser: number): RpcRequestBuilder<EMATeamObjectiveDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TeamBoardService.getTeamResults';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getTeamBoards(idUser: number): RpcRequestBuilder<TeamBoardLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TeamBoardService.getTeamBoards';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
