// DB ENUM fr.hiji.metier.mif.entities.observation.FormationObservationType generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FormationObservationType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ACQUISITION_BEFORE = new FormationObservationType(1, "Acquisition avant", "ACQUISITION_BEFORE");
  static readonly SATISFACTION_PARTICIPANT = new FormationObservationType(2, "Satisfaction participant", "SATISFACTION_PARTICIPANT");
  static readonly ACQUISITION_AFTER = new FormationObservationType(3, "Acquisition après", "ACQUISITION_AFTER");
  static readonly SATISFACTION_COMMANDITAIRE_OPCO = new FormationObservationType(4, "Satisfaction commanditaire/opco", "SATISFACTION_COMMANDITAIRE_OPCO");

  static readonly values = [
    FormationObservationType.ACQUISITION_BEFORE,
 
    FormationObservationType.SATISFACTION_PARTICIPANT,
 
    FormationObservationType.ACQUISITION_AFTER,
 
    FormationObservationType.SATISFACTION_COMMANDITAIRE_OPCO
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FormationObservationType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FormationObservationType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.observation.FormationObservationType'] = FormationObservationType;
reverseMapping[FormationObservationType.name] = 'fr.hiji.metier.mif.entities.observation.FormationObservationType';
