// ENTITY com.ic2.rpc.tokens.FileToken generated by ic2 3.0.0-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FileToken {

  token: string | null = '';
  checksum: string | null = '';
  filename: string | null = '';

  constructor(init?:Partial<FileToken>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.rpc.tokens.FileToken'] = FileToken;

reverseMapping[FileToken.name] = 'com.ic2.rpc.tokens.FileToken';

fields['com.ic2.rpc.tokens.FileToken']  = {
    token: 'java.lang.String',
    checksum: 'java.lang.String',
    filename: 'java.lang.String'
};
