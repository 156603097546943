// ENTITY fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionQCMAnswerDistributionBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationQuizQuestionQCMAnswerDistributionBODTO {

  distribution: number | null = 0;
  nbQuestionAnswered: number | null = 0;
  idQuestion: number | null = 0;
  question: MultilingualString | null = new MultilingualString();
  questionImage: MultilingualString | null = new MultilingualString();
  answer: MultilingualString | null = new MultilingualString();
  answerImage: MultilingualString | null = new MultilingualString();
  isGoodAnswer: boolean | null = false;

  constructor(init?:Partial<FormationQuizQuestionQCMAnswerDistributionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionQCMAnswerDistributionBODTO'] = FormationQuizQuestionQCMAnswerDistributionBODTO;

reverseMapping[FormationQuizQuestionQCMAnswerDistributionBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionQCMAnswerDistributionBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionQCMAnswerDistributionBODTO']  = {
    distribution: 'java.lang.Integer',
    nbQuestionAnswered: 'java.lang.Integer',
    idQuestion: 'java.lang.Integer',
    question: 'com.ic2.sc.MultilingualString',
    questionImage: 'com.ic2.sc.MultilingualString',
    answer: 'com.ic2.sc.MultilingualString',
    answerImage: 'com.ic2.sc.MultilingualString',
    isGoodAnswer: 'java.lang.Boolean'
};
