// fr.hiji.metier.certif.services.front.ModalityServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ModalityInstructionDTO } from '../entities/ModalityInstructionDTO';

@Injectable({
  providedIn: 'root',
})
export class ModalityService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  setModalityAccessForAllCandidates(idCertificationSession: number, idModality: number, accessible: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModalityService.setModalityAccessForAllCandidates';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCertificationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(accessible, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getModalityInstructions(idExam: number): RpcRequestBuilder<ModalityInstructionDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModalityService.getModalityInstructions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.modality.ModalityInstructionDTO');
  }

  toggleModalityAccess(idExam: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModalityService.toggleModalityAccess';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
