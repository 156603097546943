// ENTITY fr.hiji.metier.home.dto.front.HomeDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionActionDTO } from './FormationSessionActionDTO';
import { FeedbackUserReactionDTO } from './FeedbackUserReactionDTO';
import { FeedbackSolicitationDTO } from './FeedbackSolicitationDTO';
import { InquiryAnswerDTO } from './InquiryAnswerDTO';
import { FormationEVADTO } from './FormationEVADTO';
import { InquiryCreateDTO } from './InquiryCreateDTO';
import { SurveyItemDTO } from './SurveyItemDTO';
import { ManagerInvitationDTO } from './ManagerInvitationDTO';
import { CertificationSessionLiteDTO } from './CertificationSessionLiteDTO';
import { TeamBoardLiteDTO } from './TeamBoardLiteDTO';
import { FormationSessionLiteDTO } from './FormationSessionLiteDTO';
import { TeamMemberDTO } from './TeamMemberDTO';
import { FormationEventLiteDTO } from './FormationEventLiteDTO';
import { FormationSessionExpectationsManagerDTO } from './FormationSessionExpectationsManagerDTO';
import { FeedbackDTO } from './FeedbackDTO';

export class HomeDTO {

  me: TeamMemberDTO | null = null;
  surveys: SurveyItemDTO[] | null = [];
  team: TeamMemberDTO[] | null = [];
  feedbacks: FeedbackDTO[] | null = [];
  feedbackSolicitations: FeedbackSolicitationDTO[] | null = [];
  feedbackReactions: FeedbackUserReactionDTO[] | null = [];
  inquiryToCreate: InquiryCreateDTO[] | null = [];
  inquiryToAnswer: InquiryAnswerDTO[] | null = [];
  certificationSessionLiteDTO: CertificationSessionLiteDTO[] | null = [];
  formationSessions: FormationSessionLiteDTO[] | null = [];
  formationEvents: FormationEventLiteDTO[] | null = [];
  evas: FormationEVADTO[] | null = [];
  expectations: FormationSessionExpectationsManagerDTO[] | null = [];
  actions: FormationSessionActionDTO[] | null = [];
  teamBoards: TeamBoardLiteDTO[] | null = [];
  managerInvitations: ManagerInvitationDTO[] | null = [];
  hasGroups: boolean | null = false;

  constructor(init?:Partial<HomeDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.HomeDTO'] = HomeDTO;

reverseMapping[HomeDTO.name] = 'fr.hiji.metier.home.dto.front.HomeDTO';

fields['fr.hiji.metier.home.dto.front.HomeDTO']  = {
    me: 'fr.hiji.metier.user.dto.front.TeamMemberDTO',
    surveys: 'java.util.List<fr.hiji.metier.home.dto.front.SurveyItemDTO>',
    team: 'java.util.List<fr.hiji.metier.user.dto.front.TeamMemberDTO>',
    feedbacks: 'java.util.List<fr.hiji.metier.fbs.dto.FeedbackDTO>',
    feedbackSolicitations: 'java.util.List<fr.hiji.metier.home.dto.front.FeedbackSolicitationDTO>',
    feedbackReactions: 'java.util.List<fr.hiji.metier.home.dto.front.FeedbackUserReactionDTO>',
    inquiryToCreate: 'java.util.List<fr.hiji.metier.home.dto.front.InquiryCreateDTO>',
    inquiryToAnswer: 'java.util.List<fr.hiji.metier.home.dto.front.InquiryAnswerDTO>',
    certificationSessionLiteDTO: 'java.util.List<fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionLiteDTO>',
    formationSessions: 'java.util.List<fr.hiji.metier.home.dto.front.FormationSessionLiteDTO>',
    formationEvents: 'java.util.List<fr.hiji.metier.home.dto.front.FormationEventLiteDTO>',
    evas: 'java.util.List<fr.hiji.metier.home.dto.front.FormationEVADTO>',
    expectations: 'java.util.List<fr.hiji.metier.home.dto.front.FormationSessionExpectationsManagerDTO>',
    actions: 'java.util.List<fr.hiji.metier.home.dto.front.FormationSessionActionDTO>',
    teamBoards: 'java.util.List<fr.hiji.metier.teamboard.dto.TeamBoardLiteDTO>',
    managerInvitations: 'java.util.List<fr.hiji.metier.home.dto.front.ManagerInvitationDTO>',
    hasGroups: 'java.lang.Boolean'
};
