// ENTITY fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizQCMQuestionAnswerBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationQuizQCMQuestionAnswerBODTO {

  numAnswer: number | null = 0;
  nb: number | null = 0;

  constructor(init?:Partial<CertificationQuizQCMQuestionAnswerBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizQCMQuestionAnswerBODTO'] = CertificationQuizQCMQuestionAnswerBODTO;

reverseMapping[CertificationQuizQCMQuestionAnswerBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizQCMQuestionAnswerBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizQCMQuestionAnswerBODTO']  = {
    numAnswer: 'java.lang.Integer',
    nb: 'java.lang.Integer'
};
