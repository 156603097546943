// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleBilanDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleBilanDTO {

  moduleBilan: boolean | null = false;
  bilanFeaturePopulation: number | null = 0;

  constructor(init?:Partial<ModuleBilanDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleBilanDTO'] = ModuleBilanDTO;

reverseMapping[ModuleBilanDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleBilanDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleBilanDTO']  = {
    moduleBilan: 'java.lang.Boolean',
    bilanFeaturePopulation: 'java.lang.Integer'
};
