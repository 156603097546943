// ENTITY fr.hiji.metier.opinion.entities.OpinionQuestion generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class OpinionQuestion {

  idOpinionQuestion: number | null = 0;
  question: MultilingualString | null = new MultilingualString();
  shownFor180AutoObservation: boolean | null = false;
  shownFor180Observation: boolean | null = false;
  nps: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<OpinionQuestion>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.opinion.entities.OpinionQuestion'] = OpinionQuestion;

reverseMapping[OpinionQuestion.name] = 'fr.hiji.metier.opinion.entities.OpinionQuestion';

fields['fr.hiji.metier.opinion.entities.OpinionQuestion']  = {
    idOpinionQuestion: 'java.lang.Integer',
    question: 'com.ic2.sc.MultilingualString',
    shownFor180AutoObservation: 'java.lang.Boolean',
    shownFor180Observation: 'java.lang.Boolean',
    nps: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
