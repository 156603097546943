// fr.hiji.metier.user.services.back.ImportUserBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:03 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ImportResultDTO } from '../entities/ImportResultDTO';

@Injectable({
  providedIn: 'root',
})
export class ImportUserBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  downloadModele(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportUserBOService.downloadModele';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  importUsers(exec: boolean, removeOldUsers: boolean): RpcRequestBuilder<ImportResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportUserBOService.importUsers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(exec, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(removeOldUsers, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.ImportResultDTO');
  }

}
