// ENTITY fr.hiji.metier.mif.dto.front.event.FormationEventAnimationDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { FormationEventAnimationType } from './FormationEventAnimationType';

export class FormationEventAnimationDTO {

  idFormationEventAnimation: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  informations: MultilingualString | null = new MultilingualString();
  order: number | null = 0;
  type: FormationEventAnimationType | null = null;
  trainers: UserFuncAvatarDTO[] | null = [];

  constructor(init?:Partial<FormationEventAnimationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.event.FormationEventAnimationDTO'] = FormationEventAnimationDTO;

reverseMapping[FormationEventAnimationDTO.name] = 'fr.hiji.metier.mif.dto.front.event.FormationEventAnimationDTO';

fields['fr.hiji.metier.mif.dto.front.event.FormationEventAnimationDTO']  = {
    idFormationEventAnimation: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    informations: 'com.ic2.sc.MultilingualString',
    order: 'java.lang.Integer',
    type: 'fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationType',
    trainers: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>'
};
