// ENTITY fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionQCMAnswer generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityQuizQuestionQCMAnswer {

  idExam: number | null = 0;
  idQuestion: number | null = 0;
  numAnswer: number | null = 0;
  date: Date | null = null;

  constructor(init?:Partial<ModalityQuizQuestionQCMAnswer>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionQCMAnswer'] = ModalityQuizQuestionQCMAnswer;

reverseMapping[ModalityQuizQuestionQCMAnswer.name] = 'fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionQCMAnswer';

fields['fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionQCMAnswer']  = {
    idExam: 'java.lang.Integer',
    idQuestion: 'java.lang.Integer',
    numAnswer: 'java.lang.Integer',
    date: 'java.time.Instant'
};
