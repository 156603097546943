// ENTITY fr.hiji.metier.reporting.dto.survey.SurveyReportObservableGestureDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { SurveyReportObservableGesture } from './SurveyReportObservableGesture';
import { CriteriaType } from './CriteriaType';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class SurveyReportObservableGestureDTO {

  surveyReportObservableGesture: SurveyReportObservableGesture | null = null;
  idCriteria: number | null = 0;
  criteriaType: CriteriaType | null = null;
  observableGestureName: MultilingualString | null = new MultilingualString();
  benchmarkCode: string | null = '';
  tags: ObservableGestureFlatTag[] | null = [];
  positive: number | null = 0;
  neutral: number | null = 0;
  negative: number | null = 0;
  verynegative: number | null = 0;

  constructor(init?:Partial<SurveyReportObservableGestureDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.survey.SurveyReportObservableGestureDTO'] = SurveyReportObservableGestureDTO;

reverseMapping[SurveyReportObservableGestureDTO.name] = 'fr.hiji.metier.reporting.dto.survey.SurveyReportObservableGestureDTO';

fields['fr.hiji.metier.reporting.dto.survey.SurveyReportObservableGestureDTO']  = {
    surveyReportObservableGesture: 'fr.hiji.metier.reporting.entities.survey.SurveyReportObservableGesture',
    idCriteria: 'java.lang.Integer',
    criteriaType: 'fr.hiji.metier.referential.entities.CriteriaType',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    benchmarkCode: 'java.lang.String',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>',
    positive: 'java.lang.Double',
    neutral: 'java.lang.Double',
    negative: 'java.lang.Double',
    verynegative: 'java.lang.Double'
};
