// fr.hiji.metier.mif.services.back.formation.stats.FormationStatsBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FormationStatsInfoBODTO } from '../entities/FormationStatsInfoBODTO';
import { FormationSessionParticipantAction } from '../entities/FormationSessionParticipantAction';
import { AcquisitionMIFStatsBODTO } from '../entities/AcquisitionMIFStatsBODTO';
import { QuizDataStatsMIFDTO } from '../entities/QuizDataStatsMIFDTO';
import { FormationExpectationStatsBODTO } from '../entities/FormationExpectationStatsBODTO';
import { FormationStatsFiltersBODTO } from '../entities/FormationStatsFiltersBODTO';
import { FormationQuizQuestionQCMAnswerDistributionBODTO } from '../entities/FormationQuizQuestionQCMAnswerDistributionBODTO';
import { ActivationMIFStatsBODTO } from '../entities/ActivationMIFStatsBODTO';
import { BusinessMIFStatsBODTO } from '../entities/BusinessMIFStatsBODTO';
import { SatisfactionMIFStatsBODTO } from '../entities/SatisfactionMIFStatsBODTO';
import { FormationQuizItemStatsBODTO } from '../entities/FormationQuizItemStatsBODTO';

@Injectable({
  providedIn: 'root',
})
export class FormationStatsBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getActions(filter: FormationStatsFiltersBODTO, nbPerPage: number, page: number): RpcRequestBuilder<ListWithCount<FormationSessionParticipantAction>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getActions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getQuestionQCMAnswerDistribution(filter: FormationStatsFiltersBODTO, idFormationQuiz: number, idQuestion: number): RpcRequestBuilder<FormationQuizQuestionQCMAnswerDistributionBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getQuestionQCMAnswerDistribution';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationQuiz, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idQuestion, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getInfos(filter: FormationStatsFiltersBODTO): RpcRequestBuilder<FormationStatsInfoBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsInfoBODTO');
  }

  getSatisfaction(filter: FormationStatsFiltersBODTO): RpcRequestBuilder<SatisfactionMIFStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getSatisfaction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.stats.satisfaction.SatisfactionMIFStatsBODTO');
  }

  getAcquisition(filter: FormationStatsFiltersBODTO): RpcRequestBuilder<AcquisitionMIFStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getAcquisition';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.stats.acquisition.AcquisitionMIFStatsBODTO');
  }

  getQuizList(filter: FormationStatsFiltersBODTO, genericFilter: GenericFilter): RpcRequestBuilder<ListWithCount<FormationQuizItemStatsBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getQuizList';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getQuiz(filter: FormationStatsFiltersBODTO, idFormationQuiz: number): RpcRequestBuilder<QuizDataStatsMIFDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationQuiz, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reporting.dto.mif.global.QuizDataStatsMIFDTO');
  }

  getQuestionOuvertes(filter: FormationStatsFiltersBODTO, idQuestion: number, nb: number, page: number): RpcRequestBuilder<ListWithCount<String>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getQuestionOuvertes';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idQuestion, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nb, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getActivationStats(filter: FormationStatsFiltersBODTO): RpcRequestBuilder<ActivationMIFStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getActivationStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.stats.activation.ActivationMIFStatsBODTO');
  }

  getBusinessStats(filter: FormationStatsFiltersBODTO): RpcRequestBuilder<BusinessMIFStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getBusinessStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.stats.business.BusinessMIFStatsBODTO');
  }

  getBusinessExpectations(filter: FormationStatsFiltersBODTO, nbPerPage: number, page: number): RpcRequestBuilder<ListWithCount<FormationExpectationStatsBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getBusinessExpectations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nbPerPage, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getParticipantExpectations(filter: FormationStatsFiltersBODTO, nb: number, page: number): RpcRequestBuilder<ListWithCount<FormationExpectationStatsBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getParticipantExpectations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nb, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getVerbatims(filter: FormationStatsFiltersBODTO, idObservableGesture: number, nb: number, page: number, acquisition: boolean): RpcRequestBuilder<ListWithCount<String>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.getVerbatims';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nb, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(acquisition, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  downloadSkillCSV(filter: FormationStatsFiltersBODTO, acquisition: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.downloadSkillCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(acquisition, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  downloadOGCSV(filter: FormationStatsFiltersBODTO, acquisition: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.downloadOGCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(acquisition, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  downloadVerbatimCSV(filter: FormationStatsFiltersBODTO, idObservableGesture: number, acquisition: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.downloadVerbatimCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(acquisition, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  downloadQuizVerbatimCSV(filter: FormationStatsFiltersBODTO, idQuestion: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.downloadQuizVerbatimCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idQuestion, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  downloadExpectationCSV(filter: FormationStatsFiltersBODTO, participant: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.downloadExpectationCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(participant, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  downloadActionCSV(filter: FormationStatsFiltersBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.downloadActionCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  downloadQuizCSV(fitler: FormationStatsFiltersBODTO, idFormationQuiz: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsBOService.downloadQuizCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(fitler, 'fr.hiji.metier.mif.dto.back.stats.FormationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationQuiz, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
