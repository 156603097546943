// ENTITY fr.hiji.metier.user.dto.back.PDILiteBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class PDILiteBODTO {

  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<PDILiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.PDILiteBODTO'] = PDILiteBODTO;

reverseMapping[PDILiteBODTO.name] = 'fr.hiji.metier.user.dto.back.PDILiteBODTO';

fields['fr.hiji.metier.user.dto.back.PDILiteBODTO']  = {
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString'
};
