// fr.hiji.metier.user.services.common.AutoLogServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { UserBundle } from '../entities/UserBundle';

@Injectable({
  providedIn: 'root',
})
export class AutoLogService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  loginAs(token: string): RpcRequestBuilder<UserBundle> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'AutoLogService.loginAs';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.entity.UserBundle');
  }

}
