// ENTITY fr.hiji.metier.ema.dto.front.EMALiteCoachDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { RepositoryLiteDTO } from './RepositoryLiteDTO';

export class EMALiteCoachDTO {

  idEMA: number | null = 0;
  creationDate: Date | null = null;
  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  message: string | null = '';
  supportPlan: string | null = '';
  repositories: RepositoryLiteDTO[] | null = [];

  constructor(init?:Partial<EMALiteCoachDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMALiteCoachDTO'] = EMALiteCoachDTO;

reverseMapping[EMALiteCoachDTO.name] = 'fr.hiji.metier.ema.dto.front.EMALiteCoachDTO';

fields['fr.hiji.metier.ema.dto.front.EMALiteCoachDTO']  = {
    idEMA: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    message: 'java.lang.String',
    supportPlan: 'java.lang.String',
    repositories: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryLiteDTO>'
};
