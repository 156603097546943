// fr.hiji.metier.campaign.services.back.CampaignStatsBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:03 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount } from '@ic2/ic2-lib';
import { CampaignStatsDTO } from '../entities/CampaignStatsDTO';
import { ObservableGestureChartValueDTO } from '../entities/ObservableGestureChartValueDTO';
import { CampaignStatsFilterBODTO } from '../entities/CampaignStatsFilterBODTO';

@Injectable({
  providedIn: 'root',
})
export class CampaignStatsBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getManagersStatsCsv(filters: CampaignStatsFilterBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignStatsBOService.getManagersStatsCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getEtablissementsStatsCsv(filters: CampaignStatsFilterBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignStatsBOService.getEtablissementsStatsCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getSkillStatsCsv(filters: CampaignStatsFilterBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignStatsBOService.getSkillStatsCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getObservableGestureStatsCsv(filters: CampaignStatsFilterBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignStatsBOService.getObservableGestureStatsCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getVerbatims(filters: CampaignStatsFilterBODTO, idObservableGesture: number, nb: number, page: number): RpcRequestBuilder<ListWithCount<String>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignStatsBOService.getVerbatims';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nb, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getCampaignStats(filters: CampaignStatsFilterBODTO): RpcRequestBuilder<CampaignStatsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignStatsBOService.getCampaignStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsDTO');
  }

  getObservableGestureStats(filters: CampaignStatsFilterBODTO, idObservableGesture: number): RpcRequestBuilder<ObservableGestureChartValueDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignStatsBOService.getObservableGestureStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsFilterBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
