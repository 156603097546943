// fr.hiji.metier.conf.services.back.submodules.MIFModuleCfgBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class MIFModuleCfgBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateIdCriteriaExpectation(idCriteria: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MIFModuleCfgBOService.updateIdCriteriaExpectation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCriteria, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateIdCriteriaAction(idCriteria: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MIFModuleCfgBOService.updateIdCriteriaAction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCriteria, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateIdCriteriaBusinessExpectation(idCriteria: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MIFModuleCfgBOService.updateIdCriteriaBusinessExpectation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCriteria, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateModuleMesureImpactFormation(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MIFModuleCfgBOService.updateModuleMesureImpactFormation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateAddPopulationToSessionEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'MIFModuleCfgBOService.updateAddPopulationToSessionEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
