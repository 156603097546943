// ENTITY fr.hiji.metier.campaign.dto.back.stats.EtablissementStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EtablissementStatsBODTO {

  idEtablissement: number | null = 0;
  etablissementName: string | null = '';
  etablissementDel: boolean | null = false;
  regionName: string | null = '';
  effectifConcerneNotDel: number | null = 0;
  effectifConcerneDel: number | null = 0;
  nbObs: number | null = 0;
  nbAutoObs: number | null = 0;
  nbExchanges: number | null = 0;
  nbConcernedUsersNotDeleted: number | null = 0;
  nbConcernedUsersDeleted: number | null = 0;

  constructor(init?:Partial<EtablissementStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.EtablissementStatsBODTO'] = EtablissementStatsBODTO;

reverseMapping[EtablissementStatsBODTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.EtablissementStatsBODTO';

fields['fr.hiji.metier.campaign.dto.back.stats.EtablissementStatsBODTO']  = {
    idEtablissement: 'java.lang.Integer',
    etablissementName: 'java.lang.String',
    etablissementDel: 'java.lang.Boolean',
    regionName: 'java.lang.String',
    effectifConcerneNotDel: 'java.lang.Integer',
    effectifConcerneDel: 'java.lang.Integer',
    nbObs: 'java.lang.Integer',
    nbAutoObs: 'java.lang.Integer',
    nbExchanges: 'java.lang.Integer',
    nbConcernedUsersNotDeleted: 'java.lang.Integer',
    nbConcernedUsersDeleted: 'java.lang.Integer'
};
