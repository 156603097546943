// ENTITY fr.hiji.metier.teamboard.dto.TeamBoardDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { UserSawTeamBoard } from './UserSawTeamBoard';
import { TeamBoard } from './TeamBoard';
import { EMATeamObjectiveDTO } from './EMATeamObjectiveDTO';
import { TeamBoardBriefDTO } from './TeamBoardBriefDTO';

export class TeamBoardDTO {

  board: TeamBoard | null = null;
  results: EMATeamObjectiveDTO[] | null = [];
  users: UserFuncAvatarDTO[] | null = [];
  briefs: TeamBoardBriefDTO[] | null = [];
  saw: UserSawTeamBoard[] | null = [];
  ownerOrOwnerInGlobalTeam: boolean | null = false;
  manager: UserFuncAvatarDTO | null = null;

  constructor(init?:Partial<TeamBoardDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.teamboard.dto.TeamBoardDTO'] = TeamBoardDTO;

reverseMapping[TeamBoardDTO.name] = 'fr.hiji.metier.teamboard.dto.TeamBoardDTO';

fields['fr.hiji.metier.teamboard.dto.TeamBoardDTO']  = {
    board: 'fr.hiji.metier.teamboard.entities.TeamBoard',
    results: 'java.util.List<fr.hiji.metier.teamboard.dto.EMATeamObjectiveDTO>',
    users: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>',
    briefs: 'java.util.List<fr.hiji.metier.teamboard.dto.TeamBoardBriefDTO>',
    saw: 'java.util.List<fr.hiji.metier.teamboard.entities.UserSawTeamBoard>',
    ownerOrOwnerInGlobalTeam: 'java.lang.Boolean',
    manager: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO'
};
