// fr.hiji.metier.file.services.HijiFileServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { HijiFileDTO } from '../entities/HijiFileDTO';

@Injectable({
  providedIn: 'root',
})
export class HijiFileService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  upload(isPrivate: boolean): RpcRequestBuilder<HijiFileDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'HijiFileService.upload';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(isPrivate, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.file.DTO.HijiFileDTO');
  }

}
