// ENTITY fr.hiji.metier.certif.dto.back.stats.roleplay.RoleplayStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CertificationStatsTagBODTO } from './CertificationStatsTagBODTO';
import { CertificationStatsObersavableGestureBODTO } from './CertificationStatsObersavableGestureBODTO';
import { CertificationStatsModalityInfoBODTO } from './CertificationStatsModalityInfoBODTO';
import { CertificationVerbatimStatsBODTO } from './CertificationVerbatimStatsBODTO';
import { Criteria } from './Criteria';

export class RoleplayStatsBODTO {

  result: CertificationStatsModalityInfoBODTO | null = null;
  averageSkillsCandidat: CertificationStatsTagBODTO[] | null = [];
  averageSkillsAssessor: CertificationStatsTagBODTO[] | null = [];
  targetSkills: CertificationStatsTagBODTO[] | null = [];
  observableGestures: CertificationStatsObersavableGestureBODTO[] | null = [];
  verbatims: CertificationVerbatimStatsBODTO[] | null = [];
  criterias: Criteria[] | null = [];

  constructor(init?:Partial<RoleplayStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.roleplay.RoleplayStatsBODTO'] = RoleplayStatsBODTO;

reverseMapping[RoleplayStatsBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.roleplay.RoleplayStatsBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.roleplay.RoleplayStatsBODTO']  = {
    result: 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityInfoBODTO',
    averageSkillsCandidat: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.CertificationStatsTagBODTO>',
    averageSkillsAssessor: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.CertificationStatsTagBODTO>',
    targetSkills: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.CertificationStatsTagBODTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.certif.dto.back.CertificationStatsObersavableGestureBODTO>',
    verbatims: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.CertificationVerbatimStatsBODTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>'
};
