// ENTITY fr.hiji.metier.reseau.entities.PopulationFilter generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class PopulationFilter {

  idPopulation: number | null = 0;
  idOrganisation: number | null = 0;
  idFonction: number | null = 0;
  idUser: number | null = 0;
  negate: boolean | null = false;

  constructor(init?:Partial<PopulationFilter>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.entities.PopulationFilter'] = PopulationFilter;

reverseMapping[PopulationFilter.name] = 'fr.hiji.metier.reseau.entities.PopulationFilter';

fields['fr.hiji.metier.reseau.entities.PopulationFilter']  = {
    idPopulation: 'java.lang.Integer',
    idOrganisation: 'java.lang.Integer',
    idFonction: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    negate: 'java.lang.Boolean'
};
