// ENTITY fr.hiji.metier.mif.dto.back.stats.FormationVerbatimStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class FormationVerbatimStatsBODTO {

  idObservableGesture: number | null = 0;
  idCriteria: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  benchmarkCode: string | null = '';
  tags: ObservableGestureFlatTag[] | null = [];

  constructor(init?:Partial<FormationVerbatimStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.FormationVerbatimStatsBODTO'] = FormationVerbatimStatsBODTO;

reverseMapping[FormationVerbatimStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.FormationVerbatimStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.FormationVerbatimStatsBODTO']  = {
    idObservableGesture: 'java.lang.Integer',
    idCriteria: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    benchmarkCode: 'java.lang.String',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>'
};
