// fr.hiji.metier.mif.services.back.formation.FormationReportBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:03 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { PPTFilterConfigMIFBODTO } from '../entities/PPTFilterConfigMIFBODTO';

@Injectable({
  providedIn: 'root',
})
export class FormationReportBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  generatePPT(dto: PPTFilterConfigMIFBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationReportBOService.generatePPT';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.reporting.dto.mif.PPTFilterConfigMIFBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  generateExcel(dto: PPTFilterConfigMIFBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationReportBOService.generateExcel';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.reporting.dto.mif.PPTFilterConfigMIFBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
