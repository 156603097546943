// ENTITY fr.hiji.metier.campaign.entities._360.Campaign360SkillTarget generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Campaign360SkillTarget {

  idCampaign: number | null = 0;
  idTag: number | null = 0;
  value: number | null = 0;

  constructor(init?:Partial<Campaign360SkillTarget>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities._360.Campaign360SkillTarget'] = Campaign360SkillTarget;

reverseMapping[Campaign360SkillTarget.name] = 'fr.hiji.metier.campaign.entities._360.Campaign360SkillTarget';

fields['fr.hiji.metier.campaign.entities._360.Campaign360SkillTarget']  = {
    idCampaign: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    value: 'java.lang.Double'
};
