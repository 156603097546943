// ENTITY fr.hiji.metier.reporting.entities.survey.SurveyReportFilter generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { AgeRange } from './AgeRange';
import { SeniorityRange } from './SeniorityRange';
import { Genre } from './Genre';

export class SurveyReportFilter {

  idCampaign: number | null = 0;
  ageRange: AgeRange | null = null;
  seniorityRange: SeniorityRange | null = null;
  gender: Genre | null = null;
  idUserEquipe: number | null = 0;
  equipeDirecte: boolean | null = false;
  equipeGlobale: boolean | null = false;
  idOrganisation: number | null = 0;
  idPopulation: number | null = 0;
  filter1: string | null = '';
  filter2: string | null = '';
  filter3: string | null = '';

  constructor(init?:Partial<SurveyReportFilter>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities.survey.SurveyReportFilter'] = SurveyReportFilter;

reverseMapping[SurveyReportFilter.name] = 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter';

fields['fr.hiji.metier.reporting.entities.survey.SurveyReportFilter']  = {
    idCampaign: 'java.lang.Integer',
    ageRange: 'fr.hiji.metier.campaign.entities.AgeRange',
    seniorityRange: 'fr.hiji.metier.campaign.entities.SeniorityRange',
    gender: 'fr.hiji.metier.user.entities.Genre',
    idUserEquipe: 'java.lang.Integer',
    equipeDirecte: 'java.lang.Boolean',
    equipeGlobale: 'java.lang.Boolean',
    idOrganisation: 'java.lang.Integer',
    idPopulation: 'java.lang.Integer',
    filter1: 'java.lang.String',
    filter2: 'java.lang.String',
    filter3: 'java.lang.String'
};
