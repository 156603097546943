// ENTITY fr.hiji.metier.badge.dto.BadgesDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class BadgesDTO {

  nbObservationsToDo: number | null = 0;
  nbExchangeToDo: number | null = 0;

  constructor(init?:Partial<BadgesDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.badge.dto.BadgesDTO'] = BadgesDTO;

reverseMapping[BadgesDTO.name] = 'fr.hiji.metier.badge.dto.BadgesDTO';

fields['fr.hiji.metier.badge.dto.BadgesDTO']  = {
    nbObservationsToDo: 'java.lang.Integer',
    nbExchangeToDo: 'java.lang.Integer'
};
