// ENTITY fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplay generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityRoleplay {

  idModality: number | null = 0;
  idRepository: number | null = 0;
  averageScoreToSucceed: number | null = 0;
  requireAvgTagMoreThanTarget: boolean | null = false;

  constructor(init?:Partial<ModalityRoleplay>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplay'] = ModalityRoleplay;

reverseMapping[ModalityRoleplay.name] = 'fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplay';

fields['fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplay']  = {
    idModality: 'java.lang.Integer',
    idRepository: 'java.lang.Integer',
    averageScoreToSucceed: 'java.lang.Double',
    requireAvgTagMoreThanTarget: 'java.lang.Boolean'
};
