// ENTITY fr.hiji.metier.mif.dto.back.event.stats.FormationEventSatisfactionBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Criteria } from './Criteria';
import { FormationEventObservableGestureStatsBODTO } from './FormationEventObservableGestureStatsBODTO';
import { FormationEventVerbatimStatsBODTO } from './FormationEventVerbatimStatsBODTO';
import { FormationEventSkillStatsBODTO } from './FormationEventSkillStatsBODTO';

export class FormationEventSatisfactionBODTO {

  totalParticipants: number | null = 0;
  totalAnswerParticipant: number | null = 0;
  averageSatisfaction: number | null = null;
  tags: FormationEventSkillStatsBODTO[] | null = [];
  observableGestures: FormationEventObservableGestureStatsBODTO[] | null = [];
  verbatims: FormationEventVerbatimStatsBODTO[] | null = [];
  criterias: Criteria[] | null = [];

  constructor(init?:Partial<FormationEventSatisfactionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.stats.FormationEventSatisfactionBODTO'] = FormationEventSatisfactionBODTO;

reverseMapping[FormationEventSatisfactionBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventSatisfactionBODTO';

fields['fr.hiji.metier.mif.dto.back.event.stats.FormationEventSatisfactionBODTO']  = {
    totalParticipants: 'java.lang.Integer',
    totalAnswerParticipant: 'java.lang.Integer',
    averageSatisfaction: 'java.lang.Double',
    tags: 'java.util.List<fr.hiji.metier.mif.dto.back.event.stats.FormationEventSkillStatsBODTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.mif.dto.back.event.stats.FormationEventObservableGestureStatsBODTO>',
    verbatims: 'java.util.List<fr.hiji.metier.mif.dto.back.event.stats.FormationEventVerbatimStatsBODTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>'
};
