// fr.hiji.metier.user.services.front.UserActionsServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { UserActionsDTO } from '../entities/UserActionsDTO';

@Injectable({
  providedIn: 'root',
})
export class UserActionsService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getActions(idUser: number): RpcRequestBuilder<UserActionsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserActionsService.getActions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.front.UserActionsDTO');
  }

}
