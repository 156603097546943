// ENTITY com.ic2.entity.UserBundle generated by ic2 3.0.0-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { User } from './User';
import { UserRight } from './UserRight';
import { UserConfig } from './UserConfig';
import { UserRelation } from './UserRelation';

export class UserBundle {

  user: User | null = null;
  config: UserConfig[] | null = [];
  rights: UserRight[] | null = [];
  relations: UserRelation[] | null = [];
  token: string | null = '';
  data: any | null = null;

  constructor(init?:Partial<UserBundle>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.entity.UserBundle'] = UserBundle;

reverseMapping[UserBundle.name] = 'com.ic2.entity.UserBundle';

fields['com.ic2.entity.UserBundle']  = {
    user: 'com.ic2.entity.User',
    config: 'java.util.List<com.ic2.entity.UserConfig>',
    rights: 'java.util.List<com.ic2.entity.UserRight>',
    relations: 'java.util.List<com.ic2.entity.UserRelation>',
    token: 'java.lang.String',
    data: 'java.lang.Object'
};
