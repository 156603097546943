// DB ENUM fr.hiji.metier.opinion.entities.OpinionType generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class OpinionType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly COLLABORATEUR = new OpinionType(1, "Collaborateur", "COLLABORATEUR");
  static readonly MANAGER = new OpinionType(2, "Manager", "MANAGER");

  static readonly values = [
    OpinionType.COLLABORATEUR,
 
    OpinionType.MANAGER
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of OpinionType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of OpinionType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.opinion.entities.OpinionType'] = OpinionType;
reverseMapping[OpinionType.name] = 'fr.hiji.metier.opinion.entities.OpinionType';
