// fr.hiji.metier.referential.services.back.RepositoryBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter, MultilingualString } from '@ic2/ic2-lib';
import { RepositoryBODTO } from '../entities/RepositoryBODTO';
import { RepositoryItemBODTO } from '../entities/RepositoryItemBODTO';
import { NbOgsPerTagDTO } from '../entities/NbOgsPerTagDTO';

@Injectable({
  providedIn: 'root',
})
export class RepositoryBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  repositoryNameAlreadyExists(name: MultilingualString, idRepository: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RepositoryBOService.repositoryNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'com.ic2.sc.MultilingualString', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idRepository, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getRepositories(filter: GenericFilter): RpcRequestBuilder<ListWithCount<RepositoryItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RepositoryBOService.getRepositories';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getRepository(idRepository: number): RpcRequestBuilder<RepositoryBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RepositoryBOService.getRepository';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idRepository, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.RepositoryBODTO');
  }

  saveRepository(r: RepositoryBODTO): RpcRequestBuilder<RepositoryBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RepositoryBOService.saveRepository';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(r, 'fr.hiji.metier.referential.dto.RepositoryBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.RepositoryBODTO');
  }

  getTagsInRepository(idRepository: number): RpcRequestBuilder<NbOgsPerTagDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RepositoryBOService.getTagsInRepository';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idRepository, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getRepositoryName(idRepository: number): RpcRequestBuilder<MultilingualString> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RepositoryBOService.getRepositoryName';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idRepository, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.sc.MultilingualString');
  }

}
