// ENTITY fr.hiji.metier.campaign.dto.front.ExchangeObservationDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ExchangeObservationObservableGestureDTO } from './ExchangeObservationObservableGestureDTO';

export class ExchangeObservationDTO {

  idObservation: number | null = 0;
  creationDate: Date | null = null;
  idUserObserved: number | null = 0;
  idUserObserver: number | null = 0;
  idRepository: number | null = 0;
  repositoryName: MultilingualString | null = new MultilingualString();
  message: string | null = '';
  observableGestures: ExchangeObservationObservableGestureDTO[] | null = [];

  constructor(init?:Partial<ExchangeObservationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.ExchangeObservationDTO'] = ExchangeObservationDTO;

reverseMapping[ExchangeObservationDTO.name] = 'fr.hiji.metier.campaign.dto.front.ExchangeObservationDTO';

fields['fr.hiji.metier.campaign.dto.front.ExchangeObservationDTO']  = {
    idObservation: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserObserved: 'java.lang.Integer',
    idUserObserver: 'java.lang.Integer',
    idRepository: 'java.lang.Integer',
    repositoryName: 'com.ic2.sc.MultilingualString',
    message: 'java.lang.String',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ExchangeObservationObservableGestureDTO>'
};
