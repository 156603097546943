// fr.hiji.metier.visualize.services.front.VisualizeServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { VizSurveyDTO } from '../entities/VizSurveyDTO';
import { VizBilanDTO } from '../entities/VizBilanDTO';
import { ExchangeItemDTO } from '../entities/ExchangeItemDTO';
import { VizInquiryAnswerDTO } from '../entities/VizInquiryAnswerDTO';
import { VisualizeDTO } from '../entities/VisualizeDTO';
import { FeedbackDTO } from '../entities/FeedbackDTO';
import { VizInquiryDTO } from '../entities/VizInquiryDTO';

@Injectable({
  providedIn: 'root',
})
export class VisualizeService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  load(monthToLoad: Date): RpcRequestBuilder<VisualizeDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VisualizeService.load';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(monthToLoad, 'java.time.Instant', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.visualize.dto.front.VisualizeDTO');
  }

  loadFeedbacks(offset: number): RpcRequestBuilder<FeedbackDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VisualizeService.loadFeedbacks';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(offset, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  loadExchanges(offset: number): RpcRequestBuilder<ExchangeItemDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VisualizeService.loadExchanges';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(offset, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  loadBilans(offset: number): RpcRequestBuilder<VizBilanDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VisualizeService.loadBilans';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(offset, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  loadSurvey(offset: number): RpcRequestBuilder<VizSurveyDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VisualizeService.loadSurvey';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(offset, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  loadInquiries(offset: number): RpcRequestBuilder<VizInquiryDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VisualizeService.loadInquiries';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(offset, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  loadInquiryAnswers(offset: number): RpcRequestBuilder<VizInquiryAnswerDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VisualizeService.loadInquiryAnswers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(offset, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
