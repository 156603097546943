// ENTITY fr.hiji.metier.mif.entities.session.FormationSessionQuizAvailable generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionQuizzResultMode } from './FormationSessionQuizzResultMode';

export class FormationSessionQuizAvailable {

  idFormationSession: number | null = 0;
  idFormationQuiz: number | null = 0;
  available: boolean | null = false;
  resultMode: FormationSessionQuizzResultMode | null = null;

  constructor(init?:Partial<FormationSessionQuizAvailable>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionQuizAvailable'] = FormationSessionQuizAvailable;

reverseMapping[FormationSessionQuizAvailable.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionQuizAvailable';

fields['fr.hiji.metier.mif.entities.session.FormationSessionQuizAvailable']  = {
    idFormationSession: 'java.lang.Integer',
    idFormationQuiz: 'java.lang.Integer',
    available: 'java.lang.Boolean',
    resultMode: 'fr.hiji.metier.mif.entities.session.FormationSessionQuizzResultMode'
};
