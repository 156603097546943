// ENTITY fr.hiji.metier.teamboard.entities.TeamBoardBrief generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class TeamBoardBrief {

  idTeamBoardBrief: number | null = 0;
  idTeamBoard: number | null = 0;
  creationDate: Date | null = null;
  message: string | null = '';

  constructor(init?:Partial<TeamBoardBrief>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.teamboard.entities.TeamBoardBrief'] = TeamBoardBrief;

reverseMapping[TeamBoardBrief.name] = 'fr.hiji.metier.teamboard.entities.TeamBoardBrief';

fields['fr.hiji.metier.teamboard.entities.TeamBoardBrief']  = {
    idTeamBoardBrief: 'java.lang.Integer',
    idTeamBoard: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    message: 'java.lang.String'
};
