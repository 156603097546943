// fr.hiji.metier.opinion.services.back.OpinionBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { OpinionQuestionBODTO } from '../entities/OpinionQuestionBODTO';
import { OpinionSuggestionBODTO } from '../entities/OpinionSuggestionBODTO';

@Injectable({
  providedIn: 'root',
})
export class OpinionBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  toggleDeleteOpinionSuggestion(id: number, isDel: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OpinionBOService.toggleDeleteOpinionSuggestion';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(isDel, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getOpinionQuestions(filter: GenericFilter): RpcRequestBuilder<ListWithCount<OpinionQuestionBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OpinionBOService.getOpinionQuestions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getOpinionQuestion(id: number): RpcRequestBuilder<OpinionQuestionBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OpinionBOService.getOpinionQuestion';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.opinion.dto.back.OpinionQuestionBODTO');
  }

  getOpinionSuggestion(filter: GenericFilter): RpcRequestBuilder<ListWithCount<OpinionSuggestionBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'OpinionBOService.getOpinionSuggestion';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

}
