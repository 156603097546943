// ENTITY fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserResponsable generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CampaignPDIUserResponsable {

  idCampaign: number | null = 0;
  idUser: number | null = 0;

  constructor(init?:Partial<CampaignPDIUserResponsable>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserResponsable'] = CampaignPDIUserResponsable;

reverseMapping[CampaignPDIUserResponsable.name] = 'fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserResponsable';

fields['fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserResponsable']  = {
    idCampaign: 'java.lang.Integer',
    idUser: 'java.lang.Integer'
};
