// ENTITY fr.hiji.metier.bilan.dto.BilanBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Bilan } from './Bilan';

export class BilanBODTO {

  bilan: Bilan | null = null;
  user: string | null = '';
  userCreator: string | null = '';
  userUpdater: string | null = '';

  constructor(init?:Partial<BilanBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.bilan.dto.BilanBODTO'] = BilanBODTO;

reverseMapping[BilanBODTO.name] = 'fr.hiji.metier.bilan.dto.BilanBODTO';

fields['fr.hiji.metier.bilan.dto.BilanBODTO']  = {
    bilan: 'fr.hiji.metier.bilan.entities.Bilan',
    user: 'java.lang.String',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String'
};
