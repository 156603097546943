// DB ENUM fr.hiji.metier.user.entities.Genre generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class Genre {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly HOMME = new Genre(1, "Homme", "HOMME");
  static readonly FEMME = new Genre(2, "Femme", "FEMME");
  static readonly NON_PRECISE = new Genre(3, "Non précisé", "NON_PRECISE");

  static readonly values = [
    Genre.HOMME,
 
    Genre.FEMME,
 
    Genre.NON_PRECISE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of Genre.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of Genre.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.user.entities.Genre'] = Genre;
reverseMapping[Genre.name] = 'fr.hiji.metier.user.entities.Genre';
