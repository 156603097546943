// ENTITY fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamSkillAvg generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityInterviewExamSkillAvg {

  idExam: number | null = 0;
  idTag: number | null = 0;
  value: number | null = 0;

  constructor(init?:Partial<ModalityInterviewExamSkillAvg>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamSkillAvg'] = ModalityInterviewExamSkillAvg;

reverseMapping[ModalityInterviewExamSkillAvg.name] = 'fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamSkillAvg';

fields['fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamSkillAvg']  = {
    idExam: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    value: 'java.lang.Double'
};
