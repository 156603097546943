// ENTITY fr.hiji.metier.mif.dto.front.trainer.ParticipantResultsDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { ParticipantQuizResults } from './ParticipantQuizResults';

export class ParticipantResultsDTO {

  user: UserFuncAvatarDTO | null = null;
  acquisitionBeforeDone: boolean | null = false;
  expectationsDone: boolean | null = false;
  acquisitionAfterDone: boolean | null = false;
  businessExpectationsDone: boolean | null = false;
  actionsDone: boolean | null = false;
  satisfactionDone: boolean | null = false;
  evaDone: boolean | null = false;
  trainerCanSeeParticipantSatisfaction: boolean | null = false;
  anonymousSatisfaction: boolean | null = false;
  quizResults: ParticipantQuizResults[] | null = [];

  constructor(init?:Partial<ParticipantResultsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.ParticipantResultsDTO'] = ParticipantResultsDTO;

reverseMapping[ParticipantResultsDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.ParticipantResultsDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.ParticipantResultsDTO']  = {
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    acquisitionBeforeDone: 'java.lang.Boolean',
    expectationsDone: 'java.lang.Boolean',
    acquisitionAfterDone: 'java.lang.Boolean',
    businessExpectationsDone: 'java.lang.Boolean',
    actionsDone: 'java.lang.Boolean',
    satisfactionDone: 'java.lang.Boolean',
    evaDone: 'java.lang.Boolean',
    trainerCanSeeParticipantSatisfaction: 'java.lang.Boolean',
    anonymousSatisfaction: 'java.lang.Boolean',
    quizResults: 'java.util.List<fr.hiji.metier.mif.dto.front.trainer.ParticipantQuizResults>'
};
