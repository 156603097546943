// ENTITY fr.hiji.metier.mif.dto.front.FormationSessionExpectationsResultDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { FormationSessionParticipantExpectation } from './FormationSessionParticipantExpectation';
import { Criteria } from './Criteria';

export class FormationSessionExpectationsResultDTO {

  expectations: FormationSessionParticipantExpectation[] | null = [];
  users: UserFuncAvatarDTO[] | null = [];
  idFormationSession: number | null = 0;
  expectationManagerEnabled: boolean | null = false;
  criteriaExpectations: Criteria | null = null;
  criteriaBusinessExpectations: Criteria | null = null;

  constructor(init?:Partial<FormationSessionExpectationsResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.FormationSessionExpectationsResultDTO'] = FormationSessionExpectationsResultDTO;

reverseMapping[FormationSessionExpectationsResultDTO.name] = 'fr.hiji.metier.mif.dto.front.FormationSessionExpectationsResultDTO';

fields['fr.hiji.metier.mif.dto.front.FormationSessionExpectationsResultDTO']  = {
    expectations: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionParticipantExpectation>',
    users: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>',
    idFormationSession: 'java.lang.Integer',
    expectationManagerEnabled: 'java.lang.Boolean',
    criteriaExpectations: 'fr.hiji.metier.referential.entities.Criteria',
    criteriaBusinessExpectations: 'fr.hiji.metier.referential.entities.Criteria'
};
