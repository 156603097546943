// ENTITY fr.hiji.metier.mif.dto.back.stats.filters.FormationSessionStatsLiteDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationSessionStatsLiteDTO {

  idFormation: number | null = 0;
  formationName: MultilingualString | null = new MultilingualString();
  formationColorCode: string | null = '';
  idFormationSession: number | null = 0;
  formationSessionName: string | null = '';
  formationSessionColorCode: string | null = '';
  minDate: Date | null = null;
  maxDate: Date | null = null;

  constructor(init?:Partial<FormationSessionStatsLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.filters.FormationSessionStatsLiteDTO'] = FormationSessionStatsLiteDTO;

reverseMapping[FormationSessionStatsLiteDTO.name] = 'fr.hiji.metier.mif.dto.back.stats.filters.FormationSessionStatsLiteDTO';

fields['fr.hiji.metier.mif.dto.back.stats.filters.FormationSessionStatsLiteDTO']  = {
    idFormation: 'java.lang.Integer',
    formationName: 'com.ic2.sc.MultilingualString',
    formationColorCode: 'java.lang.String',
    idFormationSession: 'java.lang.Integer',
    formationSessionName: 'java.lang.String',
    formationSessionColorCode: 'java.lang.String',
    minDate: 'java.time.LocalDate',
    maxDate: 'java.time.LocalDate'
};
