// ENTITY fr.hiji.metier.referential.entities.Criteria generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CriteriaType } from './CriteriaType';

export class Criteria {

  idCriteria: number | null = 0;
  type: CriteriaType | null = null;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  label1: MultilingualString | null = new MultilingualString();
  label2: MultilingualString | null = new MultilingualString();
  label3: MultilingualString | null = new MultilingualString();
  label4: MultilingualString | null = new MultilingualString();
  label5: MultilingualString | null = new MultilingualString();
  value1: number | null = 0;
  value2: number | null = 0;
  value3: number | null = 0;
  value4: number | null = 0;
  value5: number | null = 0;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  availableInEMA: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<Criteria>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.Criteria'] = Criteria;

reverseMapping[Criteria.name] = 'fr.hiji.metier.referential.entities.Criteria';

fields['fr.hiji.metier.referential.entities.Criteria']  = {
    idCriteria: 'java.lang.Integer',
    type: 'fr.hiji.metier.referential.entities.CriteriaType',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    label1: 'com.ic2.sc.MultilingualString',
    label2: 'com.ic2.sc.MultilingualString',
    label3: 'com.ic2.sc.MultilingualString',
    label4: 'com.ic2.sc.MultilingualString',
    label5: 'com.ic2.sc.MultilingualString',
    value1: 'java.lang.Integer',
    value2: 'java.lang.Integer',
    value3: 'java.lang.Integer',
    value4: 'java.lang.Integer',
    value5: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    availableInEMA: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
