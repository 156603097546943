// ENTITY fr.hiji.metier.file.DTO.HijiFileDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { HijiFile } from './HijiFile';

export class HijiFileDTO {

  file: HijiFile | null = null;
  publicPath: string | null = '';
  privateToken: string | null = '';

  constructor(init?:Partial<HijiFileDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.file.DTO.HijiFileDTO'] = HijiFileDTO;

reverseMapping[HijiFileDTO.name] = 'fr.hiji.metier.file.DTO.HijiFileDTO';

fields['fr.hiji.metier.file.DTO.HijiFileDTO']  = {
    file: 'fr.hiji.metier.file.entities.HijiFile',
    publicPath: 'java.lang.String',
    privateToken: 'java.lang.String'
};
