// ENTITY fr.hiji.metier.tools.email.dto.EmailPreviewDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EmailPreviewDTO {

  subject: string | null = '';
  body: string | null = '';

  constructor(init?:Partial<EmailPreviewDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.tools.email.dto.EmailPreviewDTO'] = EmailPreviewDTO;

reverseMapping[EmailPreviewDTO.name] = 'fr.hiji.metier.tools.email.dto.EmailPreviewDTO';

fields['fr.hiji.metier.tools.email.dto.EmailPreviewDTO']  = {
    subject: 'java.lang.String',
    body: 'java.lang.String'
};
