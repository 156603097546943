// DB ENUM fr.hiji.metier.referential.entities.HighlightType generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class HighlightType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly GESTE_CLE = new HighlightType(1, "Geste clé", "GESTE_CLE");

  static readonly values = [
    HighlightType.GESTE_CLE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of HighlightType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of HighlightType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.referential.entities.HighlightType'] = HighlightType;
reverseMapping[HighlightType.name] = 'fr.hiji.metier.referential.entities.HighlightType';
