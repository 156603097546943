// ENTITY fr.hiji.metier.visualize.dto.front.VizInquiryDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:11 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class VizInquiryDTO {

  idInquiry: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  startDate: Date | null = null;
  endDate: Date | null = null;
  nbAnswers: number | null = 0;
  nbTargets: number | null = 0;

  constructor(init?:Partial<VizInquiryDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.visualize.dto.front.VizInquiryDTO'] = VizInquiryDTO;

reverseMapping[VizInquiryDTO.name] = 'fr.hiji.metier.visualize.dto.front.VizInquiryDTO';

fields['fr.hiji.metier.visualize.dto.front.VizInquiryDTO']  = {
    idInquiry: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    nbAnswers: 'java.lang.Integer',
    nbTargets: 'java.lang.Integer'
};
