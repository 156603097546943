// ENTITY fr.hiji.metier.mif.dto.back.stats.activation.ActivationMIFStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionParticipantAction } from './FormationSessionParticipantAction';

export class ActivationMIFStatsBODTO {

  nbActions: number | null = 0;
  nbParticipantActionEnabledAndAvailable: number | null = 0;
  nbParticipantWith1Action: number | null = 0;
  nbParticipantWithEvaluation: number | null = 0;
  nbParticipantActivationEnabled: number | null = 0;
  nbEvaManager: number | null = 0;
  nbParticipantWithEvaluationManager: number | null = 0;
  nbManagerWith1ActionParticipant: number | null = 0;
  averageGlobalParticipantEvaluation: number | null = null;
  averageGlobalManagerEvaluation: number | null = null;
  actions: FormationSessionParticipantAction[] | null = [];

  constructor(init?:Partial<ActivationMIFStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.activation.ActivationMIFStatsBODTO'] = ActivationMIFStatsBODTO;

reverseMapping[ActivationMIFStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.activation.ActivationMIFStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.activation.ActivationMIFStatsBODTO']  = {
    nbActions: 'java.lang.Integer',
    nbParticipantActionEnabledAndAvailable: 'java.lang.Integer',
    nbParticipantWith1Action: 'java.lang.Integer',
    nbParticipantWithEvaluation: 'java.lang.Integer',
    nbParticipantActivationEnabled: 'java.lang.Integer',
    nbEvaManager: 'java.lang.Integer',
    nbParticipantWithEvaluationManager: 'java.lang.Integer',
    nbManagerWith1ActionParticipant: 'java.lang.Integer',
    averageGlobalParticipantEvaluation: 'java.lang.Double',
    averageGlobalManagerEvaluation: 'java.lang.Double',
    actions: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionParticipantAction>'
};
