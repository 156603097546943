// fr.hiji.metier.bilan.service.back.BilanBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { BilanBODTO } from '../entities/BilanBODTO';

@Injectable({
  providedIn: 'root',
})
export class BilanBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  downloadModele(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BilanBOService.downloadModele';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getBilans(filter: GenericFilter): RpcRequestBuilder<ListWithCount<BilanBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BilanBOService.getBilans';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getBilan(idBilan: number): RpcRequestBuilder<BilanBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BilanBOService.getBilan';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idBilan, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.bilan.dto.BilanBODTO');
  }

  saveBilan(e: BilanBODTO): RpcRequestBuilder<BilanBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BilanBOService.saveBilan';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(e, 'fr.hiji.metier.bilan.dto.BilanBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.bilan.dto.BilanBODTO');
  }

  importBilans(exec: boolean): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'BilanBOService.importBilans';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(exec, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

}
