// fr.hiji.metier.user.services.back.UserBOServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { UserItemBODTO } from '../entities/UserItemBODTO';
import { UserBODTO } from '../entities/UserBODTO';
import { UserRelationBODTO } from '../entities/UserRelationBODTO';

@Injectable({
  providedIn: 'root',
})
export class UserBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getUsers(filter: GenericFilter): RpcRequestBuilder<ListWithCount<UserItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserBOService.getUsers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getUser(idUser: number): RpcRequestBuilder<UserBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserBOService.getUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.back.UserBODTO');
  }

  saveUser(u: UserBODTO): RpcRequestBuilder<UserBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserBOService.saveUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(u, 'fr.hiji.metier.user.dto.back.UserBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.back.UserBODTO');
  }

  resetUserPassword(idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserBOService.resetUserPassword';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getTeamOf(idUser: number): RpcRequestBuilder<UserRelationBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserBOService.getTeamOf';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getCopierOf(idUser: number): RpcRequestBuilder<UserRelationBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserBOService.getCopierOf';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getTeamFor(idUser: number[]): RpcRequestBuilder<UserRelationBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserBOService.getTeamFor';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getUsersCSV(filter: GenericFilter): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserBOService.getUsersCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
