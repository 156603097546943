// DB ENUM fr.hiji.metier.fbs.entities.FeedbackRecipientType generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:12 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FeedbackRecipientType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly USER = new FeedbackRecipientType(1, "Utilisateur", "USER");
  static readonly GROUP = new FeedbackRecipientType(2, "Groupe", "GROUP");
  static readonly GLOBAL_TEAM = new FeedbackRecipientType(3, "Équipe globale", "GLOBAL_TEAM");
  static readonly DIRECT_TEAM = new FeedbackRecipientType(4, "Équipe directe", "DIRECT_TEAM");

  static readonly values = [
    FeedbackRecipientType.USER,
 
    FeedbackRecipientType.GROUP,
 
    FeedbackRecipientType.GLOBAL_TEAM,
 
    FeedbackRecipientType.DIRECT_TEAM
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FeedbackRecipientType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FeedbackRecipientType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.fbs.entities.FeedbackRecipientType'] = FeedbackRecipientType;
reverseMapping[FeedbackRecipientType.name] = 'fr.hiji.metier.fbs.entities.FeedbackRecipientType';
