// fr.hiji.metier.user.services.common.TeachOnMarsLoginServiceI generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:02 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { LoginResponse } from '../entities/LoginResponse';

@Injectable({
  providedIn: 'root',
})
export class TeachOnMarsLoginService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  login(learnerId: string, token: string, instance: string): RpcRequestBuilder<LoginResponse> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TeachOnMarsLoginService.login';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(learnerId, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(instance, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.services.dto.LoginResponse');
  }

}
