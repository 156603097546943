// ENTITY fr.hiji.metier.ema.dto.back.stats.EMAManagerStatDTO generated by ic2 0.0.1-SNAPSHOT at Sat Oct 12 13:19:10 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAPerceptionStatsBODTO } from './EMAPerceptionStatsBODTO';

export class EMAManagerStatDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  fonctionName: string | null = '';
  etablissementNames: string[] | null = [];
  regionNames: string[] | null = [];
  serviceNames: string[] | null = [];
  teamCount: number | null = 0;
  nbObs: number | null = 0;
  nbTeamAutoObs: number | null = 0;
  nbExchange: number | null = 0;
  nbExchangeUnique: number | null = 0;
  nbExchangeThisManagerDid: number | null = 0;
  perceptionStats: EMAPerceptionStatsBODTO[] | null = [];

  constructor(init?:Partial<EMAManagerStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMAManagerStatDTO'] = EMAManagerStatDTO;

reverseMapping[EMAManagerStatDTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMAManagerStatDTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMAManagerStatDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    fonctionName: 'java.lang.String',
    etablissementNames: 'java.util.List<java.lang.String>',
    regionNames: 'java.util.List<java.lang.String>',
    serviceNames: 'java.util.List<java.lang.String>',
    teamCount: 'java.lang.Integer',
    nbObs: 'java.lang.Integer',
    nbTeamAutoObs: 'java.lang.Integer',
    nbExchange: 'java.lang.Integer',
    nbExchangeUnique: 'java.lang.Integer',
    nbExchangeThisManagerDid: 'java.lang.Integer',
    perceptionStats: 'java.util.List<fr.hiji.metier.ema.dto.back.stats.EMAPerceptionStatsBODTO>'
};
